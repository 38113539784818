<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        사용자 가이드
      </div>
      <div id="sub_title">
        본 서비스는 DSD 업로드로 Footing을 진행한 검증된 엑셀파일을 기반으로 DSD를 작성하여 제공합니다.<br>
        이 가이드에서는 DSD Auto 서비스의 이용 방법을 자세히 설명합니다.
      </div>
    </div>
    <div class="Main_Contents" >
      <div class="l_menus" style="width:60%; ">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button guide_title" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                DSD Auto | 감사/검토보고서
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" >
              <div class="accordion-body">
                <br>
                AutoClosing에서는 DSD 검증을 위한 Footing 서비스를 제공하고 있습니다. <br>하지만 Footing 서비스 이후에 DSD의 기초자료가 변경되는 경우에는 다시금 DSD를 작성하여야 하며, 그러한 과정에서 발생하는 오류를 확인하려면 다시금 Footing 모듈을 구동하여야 합니다.<br>
                <br><br>
                이러한 이용자의 번거로움을 해소하기 위해 AutoClosing에서는 검증이 완료된 Footing 엑셀파일을 그대로 업로드하시면 DSD가 자동으로 산출됩니다.<br>DSD Auto가 가능한 보고서는 감사 및 검토보고서이며, 사업보고서는 조만간 별도의 서비스로 찾아뵐 예정입니다.<br>
                <br><br>
                저희 서비스를 통해 회계 업무 종사자 분들의 업무시간이 조금이라도 단축되었으면 하는 바램입니다.<br><br>

                <strong>DSD Auto 진행</strong><br><br>
                ▶ 먼저 검토를 위한 Footing Excel 결과물을 준비합니다. (DSD Footing 서비스를 통해 산출된 엑셀 파일)<br>
                ▶ Footing 산출물 엑셀파일의 금액이 변경되는 경우, 엑셀 양식에 그대로 이를 입력하고 검증 내역에 문제가 없는지 확인합니다.<br>
                ▶ 검증에 문제가 없으면, 이를 DSD Auto 서비스에 업로드합니다.<br>
                ▶ 산출물이 메일로 송부 됩니다.<br><br>

              </div>
            </div>
          </div>


        </div>

      </div>
      <br><br><br><br><br><br><br>


    </div>


  </div>
</template>

<script>


export default {
  methods:{
    insReq: function(){
      window.open('/dsdchk','_blank',"menubar=no, toolbar=no, width=450px,height=550px,top=200,left=700")

    },
  }

}
</script>

<style scoped>
.guide_pdf{
  margin-bottom:10px;
  background-color: #0e2238;
  width:120px; height:30px;
  text-align:center;
  padding-top: 4px;
  color:white;
  border-radius: 20px;
  cursor: pointer;
}
.guide_pdf p:hover {
  color:#c6cfdd !important;
}
.Guide_row{
  width:60%;
  padding-bottom: 0px !important;
}

.bot_contents span {
  font-size: 11px;
  font-weight: 300;
  color:grey;
  line-height: 20px;
}

.l_menus{
  margin-bottom: 65px;
  font-size: 14px;
  margin-top: 80px;
}
.icons{
  display: flex;
  width: 53px;
  background-color: #fff;
  height: 44px;
  padding: 6px;
  margin: auto 8px;
  border-radius: 25px;
}
.guidebox{
  display: flex;
  background-color: #dee2e6;
  width:265px;
  height:55px;
  border-radius: 30px;
  margin-bottom: 10px;
}
.l_menus a{
  text-decoration: none;
}

.guidebox p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 10px;
}

.guide_title {
  font-family: Pretendard-Regular !important;
}

.accordion-button:not(.collapsed) {
  background-color: #dee2e6;
  color:black;
  font-weight:bold;
}

.guide_red {
  color: #C20000FF;
}

.ins_btn{
  width: 130px;
  height: 35px;
  background: #00295d;
  text-align: center;
  padding-top: 8px;
  font-size: 14px;
  margin-right: 15px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}


</style>