<template>

  <div id="whole" class="container d-lg-flex align-items-lg-center content-space-t-3 content-space-lg-0 min-vh-lg-100" >
    <div class="w-100" style="height:94vh;">

      <div class="row2">
        <div class="LoginWindow">
          <form class="UserInput">
            <img class="logo" :src="logo">

            <div class="col-md-12 int_area">
              <label for="inputId" class="form-label">E-Mail</label>
              <input type="text" class="form-control" id="inputId" v-model="mail" placeholder="이메일 주소를 입력하세요." autocomplete="off">
            </div>
            <div class="col-md-12 int_area">
              <label for="inputPassword" class="form-label">Password</label>
              <input type="password" class="form-control" id="inputPassword" v-model="pass" @keyup.enter="login" placeholder="비밀번호를 입력하세요." autocomplete="off">
            </div>

            <div class="col-sm-12 sbtn" style="text-align: center">
              <div @click="login()" type="submit" id="submit" class="btn btn-primary SubmitBtn">로그인</div>
            </div>
            <router-link to="/adduser" style="text-decoration: none;"><div class="col-sm-12 newUser">회원 가입</div></router-link>
            <div class="row forgot">
              <div  class="col-sm-6 find" @click="fnPopup2">Find E-mail</div>
              <div  class="col-sm-6 find" @click="forgetPass">Forgot Password</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div id="M_footer" >
    Copyright 2023. PA Soft Co., Ltd. all rights reserved.
  </div>

  <!--     비밀번호 초기화 모달폼-->

  <button type="button" class="varify Run_P_Modal" data-bs-toggle="modal" data-bs-target="#Pass_Varidation" style="display: none;">비번 모달 실행</button>

  <div class="modal fade" id="Pass_Varidation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
    <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Password Validation</h1>
          <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
          <form autocomplete="off">
            <h4>PA Soft 사용자 비밀번호 초기화</h4>
            <div class="varid_Title">
              <span>- 회원가입시 입력하신 이메일과 성명을 입력해 주세요.</span><br>
              <span>- 등록정보가 확인 되면, 해당 이메일로 비밀번호 변경 링크를 송부 드립니다.</span><br>
              <span>- 인증메일은 최대 24시간 동안 유효 합니다.</span>
            </div>
            <div class="input_Valid">
              <div class="row mb-3">
                <div class="col-sm-8" style="margin:auto;">
                  <input type="text" class="form-control" id="pass_E_valid" placeholder="이메일 주소">
                </div>
                <div class="col-sm-8" style="margin:auto;">
                  <input type="text" class="form-control" id="pass_N_valid" placeholder="이름">
                </div>
              </div>
              <div class="col-sm-12" style="align-items: center; display: flex; justify-content: center">
                <div id="re_confirm1" class="original col-sm-2" style="background-color: #0e2238; margin-right: 10px;" @click="varifyPass">확   인</div>
              </div>
            </div>

          </form>

        </div>


      </div>
    </div>
  </div>

  <!--     메일 찾기 모달폼-->

  <button type="button" class="varify Run_E_Modal" data-bs-toggle="modal" data-bs-target="#Mail_Varidation" style="display: none;">메일 모달 실행</button>

  <div class="modal fade" id="Mail_Varidation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
    <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Find E-mail</h1>
          <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
          <form autocomplete="off">
            <h4>PA Soft 사용자 이메일 정보 조회</h4>
            <div class="varid_Title">
              <span id="notice"></span><br>
              <p id="userEmail"></p>
              <p id="res_err"></p>
            </div>
            <div class="input_Valid">
              <div class="col-sm-12" style="align-items: center; display: flex; justify-content: center">
                <button type="button" class="original col-sm-2" style="background-color: #0e2238; margin-right: 10px;" data-bs-dismiss="modal">확   인</button>
              </div>
            </div>

          </form>

        </div>


      </div>
    </div>
  </div>


</template>

<script>

// import axios from "axios";
export default {
  data() {
    return {
      logo: require('../images/AutoClosing04.png'),
      mail: '',
      pass: '',


    }
  },
  methods: {
    closeWindow(){
      const targetWindow = window.open('', 'startAuth');
      if (targetWindow && !targetWindow.closed) {
        targetWindow.close();
      }
    },
    fnPopup2() {
      this.childWindow =
          window.open('/api/nicecheck/getAuth/FIND_ID', 'startAuth',
              'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no,' +
              ' status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

      window.addEventListener('message', this.Message.bind(this));
    },

    //본인인증에서 받은 메세지를 부모윈도우에서 출력//
    Message(event) {

      if (event.data.type === 'authResult') {
        // 메시지 수신 후 창닫고 인증코드 등록하기
        const t =this
        $('.Run_E_Modal').click()

        t.closeWindow()
        //에러메세지
        if(event.data.errMsg)
        {
          document.getElementById('res_err').textContent = event.data.errMsg;
        }

        //이메일 주소 - 이메일 주소 찾기만 나옴
        if(event.data.userEmail)
        {
          t.mail = event.data.userEmail
          document.getElementById('notice').textContent = "- 회원가입시 입력하신 이메일 주소는 다음과 같습니다."
          document.getElementById('userEmail').textContent = event.data.userEmail;
        }

      }

    },

    login() {

      const t =this
      const url = '/account/login'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "mailAddr": $('#inputId').val(),
        "password": $('#inputPassword').val()
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        $store.dispatch("Userlogin",res);

      }
      function errorStat (error) {
        alert(error.response.data.message)
        t.resetPass();
      }



    },

    resetPass: function() {

      this.pass= ""
    },

    forgetPass: function (){
      $('.Run_P_Modal').click()
    },

    varifyPass() {
      const validateEmail = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/
      if (!validateEmail.test($("#pass_E_valid").val()) || !$("#pass_E_valid").val()) {
        alert("이메일 형식이 맞지 않습니다.")
        return
      }
      else{
          const url = '/account/reqChangePassword'
          const utoken =$store.state.token != null ? true : false;
          const getdata ={
            "emailAddr": $('#pass_E_valid').val(),
            "userName": $('#pass_N_valid').val()
          }

          $api.postApiData (url,utoken,successStat,errorStat,getdata);
          function successStat (res) {
            if(res.status===200){
            $('.E_modalClose').click();
            alert('비밀번호 초기화 신청이 완료 되었습니다. 이메일을 확인해 주세요.')
            }
          }
          function errorStat (error) {
            alert(error.response.data.message);
          }

      }

    }

  }
}

</script>

<style scoped>

.modal {
  --bs-modal-width:460px;
  --bs-modal-border-radius: 4px;
}

.modal-title{
  font-family: 'Pretendard-Regular';
}

.modal-header {
  height: 40px;
}
.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}


#whole {
  font-family: Pretendard-Regular;
}

#M_footer {
  margin-top: 35px;
  font-size: 12px;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #92a3bb;
  position: fixed;
  top: 90%;
  margin-left: auto;
  width: 100%;
}

.varid_Title{
  margin-bottom: 21px;
  margin-top: 15px;
  font-size: 13px;
}

#pass_E_valid,#pass_N_valid {
  color: #0e2238;
}

.original {
  width: 100px;
  height: 25px;
  border: none;
  color: white;
  background-color: #959595;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  float:right;
  cursor:pointer;
}

#whole {
  background: url("../images/login-resized03.jpg") no-repeat;
  background-position-y: 13%;
  background-size: cover;
  max-width: 100% !important;
  height:94vh;
  margin-top: 0px;
  margin-left: 0;
  margin-right: 0;
  //background-color:#526374;
  border-bottom: 1px solid #002d5c29;
}

.row2  {
  flex-shrink: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.logo{ width:400px;
  padding: 9% 24%;
}

.LoginWindow {
  position: relative; z-index:2;
  background-color: rgb(253 253 253 / 30%);
  /*margin-left: 70%;*/
  width: 440px;
  padding: 53px 20px 40px 20px;
  margin: 7% auto;
}
.int_area {
  width:400px;
  color:#fff;
}

int_area input {
  witdh:100%;
  background-color: transparent;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom : 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom:30px;
}

input::placeholder {
  color: lightgrey;
  font-weight:400;
}

.col-sm-12 {
  padding-top: 10px;
}

.SubmitBtn {
  padding-top: 8px;
  width: 140px;
  height: 39px;
  font-size: 15px !important;
  border-radius: 38px;
  border: 1px solid white;
  background-color: #bbbbbb4d;
  /* margin: 0px auto; */
}

.newUser {
  margin: 10px auto 5px auto;
  text-align: center;
  width:80px;
  border-bottom: #cdcdcd 1px solid;
  color: white;
}

.forgot {
  margin: 25px 30px 0px 30px;
  text-align: center;
  border: #cdcdcd96 1px solid;
  color: white;
  line-height: 23px;
  padding: 1px;
  font-size: 12px;
  border-radius: 5px;
}

.find {
  text-align: center;
  color: white;
  font-size: 11px !important;
  background-color: transparent;
  line-height: 23px;
  padding: 1px;
  font-size: 12px;
  cursor: pointer;
}

.find:hover {
  color: #8ddefd;
}



</style>