<template>
 <div class="chk_back">
   <div class="dsdchk_title">
    <img class="alogo" src="../images/AutoClosing06.png" width="153">
   </div>
   <div class="chk_t1">DSD검토 서비스 안내</div>
   <div class="chk_desc">
     사용하시는 DSD 파일에 오류가 있거나, 처음 구동하는 경우 DSD 양식 내용의 검토가 필요할 수 있습니다.
     <br><br>
     아래의 [DSD파일 업로드] 버튼을 클릭하여, 공시된 전년도 DSD를 제공해 주시면 내용을 파악하여 담당자가 메일로 답변 드리도록 하겠습니다.
   </div>

   <div class="dsd_inspection" >
     <div class="polchk" v-if="finished===false">
       <input type="checkbox" id="myCheckbox" @change="changeBtn">
       <label for="myCheckbox" style="font-weight:bold; margin-left: 5px; margin-bottom: 20px">전기 DSD 파일 업로드 동의</label>
     </div>
     <form method="POST" enctype="multipart/form-data" id="dsd_ins" style="display: contents" v-if="finished===false">
       <label for="dsd_insfile" class="ins_btn verify" v-if="policy===true">
         <p><svg style="margin-top:-4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
           <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z"/>
         </svg>DSD 파일 업로드</p>
       </label>
       <input class="file" type="file" id="dsd_insfile" accept=".dsd" name="multipartFile" @change="insReq">
       <div type="button" class="ins_btn_dis" v-if="policy===false"><svg style="margin-top:-4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
         <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816L7.27 1.047zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z"/>
       </svg>DSD 파일 업로드</div>
     </form>
      <br>
     <div v-if="insRequest===true" class="insStat">
       <span>파일 업로드가 완료되었습니다.<br> 빠른 시일내에 회신 드리도록 하겠습니다.</span>
     </div>
   </div>
 </div>

  <!--  로그인 모달-->
  <div class="login_Modal" v-if="loged==='notloged'">
    <div class="dsdchk_title">
      <img class="alogo" src="../images/AutoClosing07.png" width="153">
    </div>
    <div style="color:white; font-size: 13px;">로그인 정보를 찾을 수 없습니다. 다시 로그인 해 주세요.</div>
    <div style="margin-top: 60px;">

      <div class="col-md-12 int_area">
        <label for="inputId" class="form-label">E-Mail</label>
        <input type="text" class="form-control" id="inputId" placeholder="이메일 주소를 입력하세요." autocomplete="off">
      </div>
      <div class="col-md-12 int_area">
        <label for="inputPassword" class="form-label">Password</label>
        <input type="password" class="form-control" id="inputPassword" v-model="pass" @keyup.enter="login" placeholder="비밀번호를 입력하세요." autocomplete="off">
      </div>

      <div class="col-sm-12 sbtn" style="text-align: center">
        <button @click="login()" type="submit" id="submit" class="btn btn-primary SubmitBtn">로그인</button>
      </div>
    </div>
  </div>




</template>

<script>
export default {
  name:'dsd_chk',
  data () {
    return {
      loged: '',
      insRequest: false,
      policy: false,
      pass: '',
      finished:false
    }
  },

  mounted() {
    if($store.state.user_log != null) {
      console.log("체크")
      this.expireCheck()
    }
    else {this.loged='notloged';

    }

  },

  methods: {
    login : function() {
      const t = this
      const url = '/account/login'
      const utoken = $store.state.token != null ? true : false;
      const getdata = {
        "mailAddr": $('#inputId').val(),
        "password": $('#inputPassword').val()
      }

      $api.postApiData(url, utoken, successStat, errorStat, getdata);

      function successStat(res) {
        localStorage.setItem("access-token", res.data.data.token);
        localStorage.setItem("user-name", res.data.data.userName);
        localStorage.setItem("tokenAvailable", res.data.data.tokenExpire);
        localStorage.setItem("accseqnm", res.data.data.accountSeqNm);
        t.loged='loged';
      }

      function errorStat(error) {
        alert(error.response.data.message)
        t.resetPass();
      }
    },

    resetPass: function() {

      this.pass= ""
    },

    changeBtn: function(){
      var checkbox = document.getElementById('myCheckbox');
      const t=this

        if (checkbox.checked) {

          t.policy= true
        }
        else {

          t.policy=false
        }

    },
    insReq: function(){
      let a = localStorage.getItem("access-token")
      if( a != null){
        var form = $('#dsd_ins')[0];
        var formdata = new FormData(form);
        var file = formdata.get('multipartFile')

        const t =this
        const url = '/dsd/dsdCheckRequest'
        const utoken =$store.state.token != null ? true : false;
        const inputdata ={
          "inputFile": file
        }

        $api.uploadApiData (url,utoken,successStat,errorStat,inputdata);
        function successStat (res) {
          $('#dsd_ins')[0].reset();
          t.insRequest=true;
          t.finished=true;
          alert('파일을 업로드 하였습니다.');
        }
        function errorStat (error) {

          alert('파일 업로드를 실패 하였습니다.');
          /*--새로고침--*/
          window.location.href="/dsdchk"

        }

      }
      else{ alert("로그인 정보가 없습니다. 다시 로그인 해 주십시오.");
        this.loged='notloged'}

    },

    expireCheck(){
      //현재시간 가져요기
      let timezoneOffset = new Date().getTimezoneOffset() * 60000;
      let timezoneDate = new Date(Date.now() - timezoneOffset);
      // console.log(timezoneDate.toISOString().substring(0, 19))

      let C_time = new Date (timezoneDate.toISOString().substring(0, 19))


      // 만료일자 가져온 후 5분 이전으로 만료시간 조정
      const time = $store.state.t_verify
      const givenDate = new Date(time);
      // 5분을 뺄 때, 음수가 발생할 수 있으므로 새로운 날짜를 연산합니다.
      const resultDate = new Date(givenDate);
      resultDate.setMinutes(resultDate.getMinutes() - 5);



      //만료 시점 계산
      let dateA = new Date(C_time);
      let dateB = new Date(resultDate);
      // console.log(dateA)
      // console.log(dateB)
      if (dateA < dateB) {
        //만료시점을 지나지 않음
        // console.log("dateA는 dateB보다 빠릅니다.");
        this.loged='loged';
      } else {
        //만료시점을 지남
        // alert("만료시점을 지났습니다")
        localStorage.clear()
        window.location.href = '/login'
      }

    }

  }
}
</script>

<style scoped>
.int_area {
  text-align: left;
  width:400px;
  color:#fff;
  margin: auto;
}
input::placeholder {
  color: grey;
  font-weight:400;
}

int_area input {
  witdh:100%;
  background-color: transparent;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom : 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom:30px;
}
.SubmitBtn {
  width: 140px;
  height: 39px;
  font-size: 15px !important;
  border-radius: 38px;
  border: 1px solid white;
  background-color: #bbbbbb4d;
  /* margin: 0px auto; */
}

.login_Modal{
  text-align: center;
  --bs-backdrop-zindex: 200;
  --bs-backdrop-bg: #000000e3;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.dsdchk_title{
  width:200px;
  height:100px;
  margin: 40px auto 30px auto;
}
.alogo{
  margin: auto;
}


.polchk{
  font-family: Pretendard-Regular;
  font-size: 13px;
  color:#000000;
}
.dsd_inspection{
  width: 80%;
  margin: auto;
}

.chk_back{
  text-align: center;
  width: 100vw;
  height: 100vh;
  --bs-backdrop-zindex: 100;
  --bs-backdrop-bg: #f9f9f9;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
}
.file {display: none;}
.ins_btn{
  width: 170px;
  height: 35px;
  background: #00295d;
  text-align: center;
  padding-top: 8px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}
.ins_btn_dis{
  margin: auto;
  width: 170px;
  height: 35px;
  background: #cbcbcb;
  text-align: center;
  padding-top: 8px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 5px;
  cursor: default !important;
}

.insStat {
  font-size: 13px;
  color:#bd4e4e;
  margin:20px 0px -5px 0px;
  font-family: Pretendard-Regular;
}
</style>