<template>

  <div class="S_File_DN_Body">


      <form class="DN_form" autocomplete="off">
        <router-link to="/" class="">
          <img :src="logo" alt="Logo" width="153" height="" class="d-inline-block align-text-top">
        </router-link>
        <div class="Cmt_Title">
          <span class="module_name" style="color:#4a6fa6;">{{modeulName}}</span><span> 작업의 결과 파일 입니다.</span><br>
          <span>본 자료의 다운로드는 1회성으로 제공됩니다. </span><br>

        </div>
        <label class="history_title form-label"></label>
        <div class="detail_used">
          <span>▶ 작업 일시: <span class="workData">{{workingDate}}</span></span><br>
          <span>▶ 결과 파일: <span class="workData">{{outputFile}}</span></span>
        </div>
        <br>
        <div class="footer" v-if="downLoadable === true">
          <div class="valid_Footer" >
            <div id="confirm_Report" type="button" class="modal_btn original" style="font-size: 15px" @click="dnStart">다운로드 가능</div>
          </div>
        </div>

        <div class="footer" v-if="downLoadable === false">
          <div class="valid_Footer">
            <div id="confirm_Report" type="button" class="modal_btn finished" style="font-size: 15px" disabled="true">다운로드 불가능</div>
            <br>
            <span style="color:darkred; font-size: 12px;">
              [다운로드 불가능]</span><span style="font-size: 12px;"><br>
              1) 이미 다운로드가 완료된 파일인 경우<br>
              2) 검토 오류가 발생한 경우(결과파일명이 '-' 인 경우)</span>
            <br>
          </div>
        </div>
      </form>



  </div>



  <!--  Download 확인 모달-->


  <button type="button" class="varify Run_DN_Modal" data-bs-toggle="modal" data-bs-target="#S_DN_Varidation" style="display:none;">다운로드 모달 실행</button>

  <div class="modal fade" id="S_DN_Varidation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="S_DN_viewlabel" aria-hidden="true">
    <div id="S_DN_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Download Confirm</h1>
          <button type="button" class="btn-close DN_modalClose" data-bs-dismiss="modal" aria-label="Close" ></button>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
          <form autocomplete="off">
            <h4>결과파일 다운로드는 1회만 제공됩니다.</h4>
            <div class="varid_Title">
              <span>- 다운로드가 완료되면 파일은 삭제되므로, 주의해 주십시오.</span><br>
              <span>- 중단이 되지 않도록, 안정된 네트워크 환경에서 진행하시길 바랍니다.</span><br>
            </div>
            <div class="input_Valid">
              <div class="row mb-3" style="justify-content: center;">
                 <div class="col-sm-4" style="align-items: center; display: flex;">
                  <div id="re_confirm1" class="original" style="background-color: #0e2238; margin-right: 10px;" @click="dn_S_File">다운로드</div>
                 </div>
              </div>
            </div>

          </form>

        </div>

        <div class="modal-footer">
<!--          <div class="valid_Footer">-->
<!--            <div id="cancel1" class="original " data-bs-dismiss="modal" style="margin-left: 10px; ">취   소</div>-->
<!--            <div id="re_vaild1" class="original " @click="resend_varify" >재발송</div>-->

<!--          </div>-->
        </div>
      </div>
    </div>
  </div>

  <!--  RESULT키 인증 모달-->

  <button type="button" class="varify Run_R_Modal" data-bs-toggle="modal" data-bs-target="#S_File_Varidation" style="display:none;">모달 실행</button>

  <div class="modal fade" id="S_File_Varidation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="S_File_viewlabel" aria-hidden="true">
    <div id="S_File_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Secured File Download</h1>
          <button type="button" class="btn-close R_modalClose" data-bs-dismiss="modal" aria-label="Close" style="display:none"></button>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
          <form autocomplete="off">
            <h4>보안 처리된 파일을 다운로드합니다</h4>
            <div class="varid_Title">
              <span>- 본인 인증을 위하여 입력하신 이메일로 인증코드가 발송되었습니다.</span><br>
              <span>- 파일 다운로드는 1회만 제공됩니다.</span><br>
            </div>
            <div class="input_Valid">
              <div class="row mb-3">
                <div class="col-sm-7">
                  <input type="text" class="form-control" id="S_File_valid" placeholder="인증코드를 입력하세요.">
                </div>
                <div class="col-sm-4" style="align-items: center; display: flex;">
                  <div id="re_confirm1" class="original" style="background-color: #0e2238; margin-right: 10px;" @click="confirmDnResult">확   인</div>
                </div>

              </div>
              <span style="font-size: 13px; color:#4a6fa6;" v-if="relogin === true">*인증 오류가 계속되는 경우, 재로그인 후 확인해 주십시오.</span>
              <div class="relogin" v-if="relogin === true"><a href="https://autoclosing.ai/">RE-LOGIN</a></div>
            </div>

          </form>

        </div>

        <div class="modal-footer">
          <!--          <div class="valid_Footer">-->
          <!--            <div id="cancel1" class="original " data-bs-dismiss="modal" style="margin-left: 10px; ">취   소</div>-->
          <!--            <div id="re_vaild1" class="original " @click="resend_varify" >재발송</div>-->

          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>


</template>

<script>
export default {
  data(){
    return{
      logo: require('/src/components/CommonPage/images/AutoClosing02.png'),
      modeulName:'DSD Footing',
      downLoadable: true,
      workingDate: '',
      outputFile: '',
      tempKey: '',
      relogin:false
    }
  },
  mounted(){
    this.tempKey = this.$route.query.resultKey
    this.confirmDnResult()
    // this.runResultModal()
  },
  methods:{

    runResultModal(){
      $('.Run_R_Modal').click()
    },

    confirmDnResult(){
      // const key = $('#S_File_valid').val()
      // console.log(this.tempKey)
      const key = this.tempKey
      const t=this
      const url = '/securefile/check/'+ key
      const utoken =$store.state.token != null ? true : false;
      $api.getApiData(url,utoken,successStat,errorStat);

      function successStat (res) {
        if(res.status===200) {
          
          // $('.R_modalClose').click();
          const Status= res.data.data
          t.workingDate = Status.createDate;
          t.downLoadable = Status.downLoadEnable;
          t.outputFile = Status.fileName;

        }

      }
      function errorStat (error) {
        alert("파일 정보를 가져오지 못했습니다. 다시 확인해 주세요.")
        t.relogin=true;
        t.downLoadable=false;
      }
    },
    dnStart(){
      $('.Run_DN_Modal').click()

    },

    dn_S_File(){

      this.downLoadable= false;
      $('.DN_modalClose').click();
      const t=this
      const keys = t.tempKey
      const url = '/securefile/download/'+ keys
      window.location.href = $store.state.basedomain+url
    }


  }
}
</script>

<style scoped>

.modal {
  --bs-modal-width:800px;
  --bs-modal-border-radius: 4px;
}

.modal-title{
  font-family: 'Pretendard-Regular';
}

.modal-header {
  height: 40px;
}
.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-family: Pretendard-Regular;
  font-size: 15px;
  font-weight: bold;
}

.modal-body span {
  font-family: Pretendard-Regular;

}

.varid_Title{
  margin-bottom: 21px;
  margin-top: 15px;
  font-size: 13px;
}

.original {
  font-family: Pretendard-Regular;
  width: 120px;
  height: 40px;
  border: none;
  border-radius:5px;
  color: white;
  background-color: #959595;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  margin: 0px auto;
  cursor:pointer;
  padding-top:8px;
}

.S_File_DN_Body {
  display: flex;
  width:50%;
  height:100vh;
  margin:auto;
  align-items: center;
  font-family: Pretendard-Regular;
}

.Cmt_Title{
 margin-top:20px;
}

.DN_form {
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding:20px;
  width:500px;
  margin:auto;
  box-shadow: 5px 5px 5px #dddddd
;
}

.finished{
  cursor: default !important;
  border: 1px solid #dddddd;
  border-radius: 5px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  color: #b35a5a;
  background-color: white;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  margin: 0px auto;
  width:200px;
  padding-top:8px;
}

#S_DN_Window{
  width:450px !important;
}
.relogin{
  font-family: Pretendard-Regular;
  font-size: 13px;
  border:1px solid black;
  width:80px;
  border-radius: 5px;
  text-align: center;
}
.relogin a{
  text-decoration: none;
}

</style>

