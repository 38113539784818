import { createWebHistory, createRouter } from "vue-router";

// *******CommonPAGE Router 추가*******
import CommMain from "@/components/CommonPage/CommMain";
import MainPage from "@/components/CommonPage/mainpage";
import Company from "@/components/CommonPage/SubMenus/Company.vue"
import FAQ from "@/components/CommonPage/SubMenus/Company/faqPage.vue";

//모듈페이지
import Sevices from "@/components/CommonPage/SubMenus/Services";
import SeviceRoot from "@/components/CommonPage/SubMenus/Service_Root.vue"
//자유게시판
import Community from "@/components/CommonPage/SubMenus/Community";
import Comm_List from "@/components/CommonPage/SubMenus/Board/Comm_List.vue";
import Comm_List_S from "@/components/CommonPage/SubMenus/Board/Comm_List_S.vue";
import C_Create from "@/components/CommonPage/SubMenus/Board/Comm_Create.vue";
import C_Detail from "@/components/CommonPage/SubMenus/Board/Comm_Detail.vue";

//공지사항 게시판
import Notice_List from "@/components/CommonPage/SubMenus/Board_notice/Notice_List.vue";
import Notice_List_S from "@/components/CommonPage/SubMenus/Board_notice/Notice_List_S.vue";
import N_Create from "@/components/CommonPage/SubMenus/Board_notice/Notice_Create.vue";
import N_Detail from "@/components/CommonPage/SubMenus/Board_notice/Notice_Detail.vue";

//질문게시판
import Qna_List from "@/components/CommonPage/SubMenus/Board_qna/Qna_List.vue";
import Qna_List_S from "@/components/CommonPage/SubMenus/Board_qna/Qna_List_S.vue";
import Q_Create from "@/components/CommonPage/SubMenus/Board_qna/Qna_Create.vue";
import Q_Detail from "@/components/CommonPage/SubMenus/Board_qna/Qna_Detail.vue";

//카카오채널
import kakao_Ch from "@/components/CommonPage/SubMenus/Company/Kakao.vue"

//메일송부페이지
import Mail from "@/components/CommonPage/SubMenus/SendMail";

// 비회원 메일 송부 페이지
import GuestMail from "@/components/CommonPage/SubMenus/Company/GuestMail.vue"

//로그인페이지+회원가입+비밀번호 찾기
import UserLoginPage from "@/components/CommonPage/accounts/login";
import AddUser from "@/components/CommonPage/accounts/adduser";
import resetPW from "@/components/CommonPage/accounts/passReset.vue"

//회원정보페이지

import accPage from "@/components/CommonPage/accounts/account_Page";
import accInfo from "@/components/CommonPage/accounts/acc_Info";
import accHistory from "@/components/CommonPage/accounts/acc_History";
import secureDN from "@/components/CommonPage/accounts/secure_DN.vue"

// USER 모듈 페이지 추가
//리스자동화
import LeaseGuide from "./components/CommonPage/UserModules/LeaseGuide"
import LeaseForm from "./components/CommonPage/UserModules/LeaseForm"
import LeaseRun from "./components/CommonPage/UserModules/LeaseRun"

//DSD Footing
import dFooterGuide from "./components/CommonPage/UserModules/dFooterGuide.vue"
import dFooterRun from "./components/CommonPage/UserModules/dFooterRun.vue"
import dFooterRun2 from "./components/CommonPage/UserModules/dFooterRun2.vue"
import DSDCHK from "./components/CommonPage/UserModules/dsdChk.vue"

//DSD Auto
import dAutoGuide from "./components/CommonPage/UserModules/dAutoGuide.vue"
import dAutoRun from "./components/CommonPage/UserModules/dAutoRun.vue"

//공사중 페이지
import Construction from "./components/CommonPage/UserModules/Constructions"

//404페이지
import Page404 from "./components/CommonPage/404_page.vue"

//임시점검페이지
import TempPage from "./components/CommonPage/Temp_page.vue"

//페이지 가드
const rejectmove = (to, from, next) => {
    let a = localStorage.getItem("access-token")
    if( a != null){
        next();
    }
    else{ alert("로그인 정보가 없습니다. 로그인 페이지로 이동합니다.");
        next("/login")}
}

const construction = (to,from,next) => {
    let status = $store.state.webStatus
    if(status) {
        next()
    }
    else{
        router.push("/temp")
    }
}



const routes = [

    {
        path: "/",
        name: "CommMain",
        beforeEnter: construction,
        component: CommMain,
        children:[
            {
                path: "/",
                name: "MainPage",
                component: MainPage
            },
            {
                path: "company",
                name: "Company",
                component: Company
            },
            {
                path: "svcs",
                name: "Services",
                component: Sevices,
                children:[
                    {
                      path: "m",
                      name: "ServiceMain",
                      component: SeviceRoot
                    },
                    {
                        path: "leaseg",
                        name: "LeaseGuide",
                        component: LeaseGuide
                    },
                    {
                        path: "leasef",
                        name: "LeaseForm",
                        component: LeaseForm
                    },
                    {
                        path: "leaser",
                        name: "LeaseRun",
                        component: LeaseRun
                    },
                    {
                        path: "dsdfg",
                        name: "DsdFooterGuide",
                        component: dFooterGuide
                    },
                    {
                        path: "dsdfr",
                        name: "DSDFooterRun",
                        component: dFooterRun
                    },
                    {
                        path: "dsdfr2",
                        name: "DSDFooterRun2",
                        component: dFooterRun2
                    },
                    {
                        path: "dsdatg",
                        name: "DSDAutoGuide",
                        component: dAutoGuide
                    },
                    {
                        path: "dsdatr",
                        name: "DSDAutoRun",
                        component: dAutoRun
                    },

                    {
                        path: "Const",
                        name: "Construction",
                        component: Construction
                    }

                ]
            },
            {
                path: "cmnt",
                name: "Community",
                component: Community,
                children:[
                    {
                        path: "nlist/:page",
                        name: "Notice_List",
                        component: Notice_List
                    },
                    {
                        path: "nlist/s/:page",
                        name: "Notice_List_S",
                        component: Notice_List_S,
                        props: true
                    },
                    {
                        path: "ndetail/:contentId",
                        name: "N_Detail",
                        component: N_Detail,
                        props: true
                    },
                    {
                        path: "ncreate/:contentId?",
                        name: "N_Create",
                        beforeEnter: rejectmove,
                        component: N_Create,
                        props: true
                    },
                    {
                        path: "list/:page",
                        name: "Comm_List",
                        component: Comm_List
                    },
                    {
                        path: "list/s/:page",
                        name: "Comm_List_S",
                        component: Comm_List_S,
                        props: true
                    },
                    {
                        path: "detail/:contentId",
                        name: "C_Detail",
                        component: C_Detail,
                        props: true
                    },
                    {
                        path: "create/:contentId?",
                        name: "C_Create",
                        beforeEnter: rejectmove,
                        component: C_Create,
                        props: true
                    },
                    {
                        path: "qnalist/:page",
                        name: "Qna_List",
                        component: Qna_List
                    },
                    {
                        path: "qcreate/:contentId?",
                        name: "Q_Create",
                        beforeEnter: rejectmove,
                        component: Q_Create,
                        props: true
                    },
                    {
                        path: "qdetail/:contentId",
                        name: "Q_Detail",
                        component: Q_Detail,
                        props: true
                    },
                    {
                        path: "qnalist/s/:page",
                        name: "Qna_List_S",
                        component: Qna_List_S,
                        props: true
                    },
                    {
                        path: "kakaoch",
                        name: "kakao_ch",
                        component: kakao_Ch
                    },
                ]
            },
            {
                path: "/gmail",
                name: "GuestMail",
                beforeEnter: rejectmove,
                component: GuestMail,
            },
            {
                path: "/faq",
                name: "FAQ",
                component: FAQ
            },
            {
                path: "/login",
                name: "UserLoginPage",
                component: UserLoginPage
            },
            {
                path: "/adduser",
                name: "AddUser",
                component: AddUser
            },
            {
                path: "accpage",
                name: "accountPage",
                beforeEnter: rejectmove,
                component: accPage,
                children: [
                    {
                        path: "myinfo",
                        name: "accInfo",
                        component: accInfo
                    },
                    {
                        path: "history/:page",
                        name: "accHistory",
                        component: accHistory
                    },


                ]
            },
            {
                path: "404",
                name: "page404",
                component: Page404
            },
            // 404페이지로 리다이렉트
            {
                // 등록된 페이지 주소 외에는 모두 /404로 이동
                path: "/:pathMatch(.*)*",
                redirect: "/404",
            },


        ]
    },
    {
        path: "/secured",
        name: "SecuredFileDN",
        component: secureDN,
        props: true
    },
    {
        path: "/change_pwd",
        name: "resetPassword",
        component: resetPW,
        props: true
    },


    {
        path: "/dsdchk",
        name: "DSD_CHECK",
        component: DSDCHK
    },
    {
        path: "/temp",
        name: "TempPage",
        component: TempPage
    }

    // {
    //     path: "/admin",
    //     name: "Login",
    //     // beforeEnter: rejectmove1,
    //     component: LoginPage,
    // },
    //
    // {
    //     path: "/main",
    //     name: "MainArea",
    //     // beforeEnter: rejectmove2,
    //     component: MainArea,
    //     children:[
    //         {
    //             path: "dash",
    //             name: "DashBoard",
    //             component: DashBoard
    //         },
    //         {
    //             path: "userlist",
    //             name: "UserList",
    //             component: UserList,
    //         },
    //         {
    //             path: "userlist/detail/:contentId",
    //             name: "UserDetail",
    //             component: UserDetail,
    //             // beforeEnter: Detail_Guard
    //         },
    //         {
    //             path: "mdset",
    //             name: "ModuleSettings",
    //             component: ModuleSettings
    //         },
    //         {
    //             path: "logs",
    //             name: "FailedLogManage",
    //             component: FailedLogManage
    //         },
    //         {
    //             path: "notice",
    //             name: "NoticeBoard",
    //             component: NoticeBoard
    //         },
    //         {
    //             path: "qna",
    //             name: "QnABoard",
    //             component: QnABoard,
    //         },
    //         {
    //             path: "notice/detail/:contentId",
    //             name: "NoticeDetail",
    //             component: NoticeDetail,
    //         },
    //         {
    //             path: "notice/create/:contentId?",
    //             name: "NoticeCreate",
    //             component: NoticeCreate,
    //         },
    //         {
    //             path: "qna/detail/:contentId",
    //             name: "QnADetail",
    //             component: QnADetail,
    //         },
    //         {
    //             path: "qna/create/:contentId?",
    //             name: "QnACreate",
    //             component: QnACreate,
    //         }
    //
    //     ]
    // },



];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

