<template>
  <div>
    <div id="menu_List" style="margin-top: 61px;">
      <p class="HeadLine">마이페이지</p>
      <ul class="ul_style">
        <li class="menu_link">

          <a to="" class="r_link" href="#BoardCollapse" data-bs-toggle="collapsed" role="button" aria-expanded="true" aria-controls="BoardCollapse" style="color: #cde3fd;" disabled>
            <svg style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-gear" viewBox="0 0 16 16">
              <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
            </svg>
            <span>회원정보 관리</span>
          </a>
          <div class="collapsed" id="BoardCollapse" style="">
            <ul class="sub_items">
              <li class="nav-item">
                <router-link to="/accpage/myinfo" >
                  회원 정보
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/accpage/history/1" >
                  이용 내역
                </router-link>
              </li>
<!--              <li class="nav-item">-->
<!--                <router-link to="/accpage/qna" >-->
<!--                  고객 센터-->
<!--                </router-link>-->
<!--              </li>-->
            </ul>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {

  mounted() {

  },
  methods: {

  }
}
</script>


<style scoped>
.HeadLine{
  margin-left: -11px;
  font-size: 17px;
  font-family: pretendard-regular;
  color: white;
  font-weight: 400;
}

.r_link {
  font-family: pretendard-regular;
  color: #b6c8dd !important;
  font-weight: 400 !important;
  font-size: 14px;
  margin-bottom: -14px;
}

.ul_style {
  margin-top: 0;
  box-sizing: border-box;
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.90625rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  list-style: none;
  padding-left: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  flex-direction: column;
  margin-left: calc(var(--bs-navbar-padding-x)*-2.5);
  margin-right: calc(var(--bs-navbar-padding-x)*-1);

}

.ul_style li {
  position: relative;
  box-sizing: border-box;
  display: list-item;
  text-align: -webkit-match-parent;
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.90625rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  list-style: none;
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;

}

.ul_style li a {
  align-items: center;
  border-bottom-right-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  display: block;
//color: var(--bs-nav-link-color);
//font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  text-decoration: none;
  margin-right: 1.875rem;
  /*padding-left: var(--bs-navbar-nav-link-padding-x);*/
  padding-left: 20px;
  font-size: 14px;
  line-height: 30px;
  color:grey;

}

.ul_style li a:hover {color:#7ea2cb !important; }

.sub_items {

  padding-left: 0px;
}

.sub_items a {
  padding: 3px 0px 3px 60px !important;
}

svg {
  margin-right: 0.625rem;
}

.router-link-active {
  color:white !important;
//background-color: #434a52;
  transition: 0.1s;
}

</style>


