<template>
<div>
    <div id="IpBanner">   
        <div  class="fcadescript mb-5">
          <div class="container content-space-2 content-space-lg-3">
              <div class="row">
                <div class="col-lg-12 mb-9 mb-lg-0">
                    <h1 class="sol-001">COMPANY<span class="sol-002"> 회사소개</span></h1>
    <!--                <div class="sol-003">-->
    <!--                  <span class="sol-004" style="display: block;"> Services Providing Efficiency & Accuracy</span>-->
    <!--                  <img src="@/assets/journal-bookmark-fill.svg" class="bnsvg" alt="...">-->
    <!--                  <img src="@/assets/chart-flow.svg" class="bnsvg" alt="...">-->
    <!--                  <img src="@/assets/chart-pie.svg" class="bnsvg" alt="...">-->
    <!--                  <img src="@/assets/card-checklist.svg" class="bnsvg" alt="...">-->
    <!--                  <img src="@/assets/law-document.svg" class="bnsvg" alt="...">-->
    <!--                  <img src="@/assets/dashboard-web.svg" class="bnsvg" alt="...">-->
    <!--                </div>-->
                </div>
              </div>
          </div>
        </div>
    </div>

    <div>
      <div class="fcadescript2">
          <div  class="infomain container content-space-2 content-space-lg-3">
            <div class="greeting"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-fill" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
            </svg> 인삿말</div>

            AutoClosing은 복잡하고 어려운 회계 결산 프로세스를 자동화 하여,<br>

            회계업계 종사자들의 효율적인 시간관리를 돕고자 설립된 PA Soft의 플랫폼 서비스입니다.<br>

            <br>
            AutoClosing의 목표는 회계팀의 야근없는 결산업무 진행입니다.<br>

            비효율적인 과도한 엑셀작업과 휴먼에러에서 벗어난 결산작업을 꿈꾸고 있습니다.<br>
            결산시기에 하루, 심지어 한시간이 다급한 회계팀을 위한 업무 파트너가 되고자 합니다.<br>

            <br>

            내년 초 서비스 될 XBRL 작성기, DSD 작성기 등의 모듈이 현재 추가로 개발 중이오니<br>

            보다 업무에 도움이 되는 내용이 있다면 언제든지 고객센터를 통한 의견 전달 부탁드립니다.<br>

            <br>

            감사합니다.<br>
            <br>

            <span style="font-size: 16px; color:#156dc1; font-weight: 900;">PA Soft 대표이사</span><br>
          </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'companyInfo',
}
</script>

<style scoped>


.fcadescript{height:200px;
background: linear-gradient(to top, rgb(0 0 0 / 10%), rgba(255, 255, 255, 0));

}



.fcadescript2{
    padding-top:75px; padding-bottom:100px !important; }

#IpBanner {
  background-image: url(@/components/CommonPage/images/companyimg.jpg);
  background-repeat: no-repeat; /* 배경이미지X */
  background-size: cover; /* 요소를 비율에 맞게 커버 */
  background-position: center; /* 이미지를 요소의 정가운데로 처리 */
  //background-attachment: fixed; /* 스크롤바 움직일때 이미지가 따라다님 */
}

.sol-001{padding:64px 50px 15px 50px;
color:white;
font-size:39px;
font-weight:700;
font-family: Pretendard-Regular;
}

.sol-002{color:white;
padding-left:20px;
font-family: Pretendard-Regular;
font-size:25px;}

.greeting{
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 800;
}

.infomain{
  padding-left: 15%;
  font-size: 16px;
  line-height: 29px;
}


</style>