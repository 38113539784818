<template>
  <div class="construction">
    <div class="row">
      <div class="col-12" style="width:300px;" >
        <div style="text-align: center">
          <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-exclamation-octagon" viewBox="0 0 16 16">
          <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
          <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
        </svg>
        </div>
        <p class="prepare">서비스를 준비중입니다.</p>
      </div>
    </div>

  </div>
</template>

<script>

</script>

<style scoped>
.construction {
  width:70%;
  display: flex;
  align-items: center;
  justify-content: center;
  height:600px;
}

.prepare {
  margin-top: 15px;
  text-align: center;
  font-size: 15px;
}
</style>