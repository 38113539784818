<template>
 <div class="addForm" >

   <form @submit="submitUser" style="width:800px;" autocomplete="off">
<!--     // 상단 문구 //-->
     <div class="row mb-3">
       <div class="col-sm-12">
         <span style="color:#0e2238; font-weight: bold; font-size:20px; border-bottom: #0e2238 1px solid;">
           안녕하세요. AutoClosing 입니다.
         </span><br><br>
         <span>결산자동화를 기반으로, 당신의 회계업무파트너가 되어줄 AutoClosing 서비스에 오신 것을 환영합니다.</span><br>
         PA Soft가 제공하는 AutoClosing 서비스 모듈, 커뮤니티 등의 서비스를 자유롭게 이용해 보세요.<br>
         <br><span id="must">*</span> 표시는 필수입력항목입니다.
       </div>
     </div>
     <hr><hr>
     <!--     //이름 입력//-->
     <div class="row mb-3">
       <label for="userName" class="col-sm-3 col-form-label">이 름<span class="must">*</span></label>
       <div class="col-sm-4">
         <input type="text" class="form-control" id="userName" v-model="userName" placeholder="휴대폰 본인인증을 진행해 주세요." disabled>
       </div>

     </div>
     <!--     //휴대전화 입력//-->
     <div class="row mb-3">
       <label for="phone" class="col-sm-3 col-form-label">휴대전화<span class="must">*</span></label>
       <div class="col-sm-4">
         <input type="tel" class="form-control" id="phone" v-model="phoneNumber" placeholder="" disabled>
       </div>
       <div class="col-sm-2 " style="" v-if="mobileAuth===false"><button type="button" class="varify" @click="varifySelf" >본인인증</button></div>
       <div class="col-sm-2 " v-if="mobileAuth===true"><button type="button" class="varify_disable"  disabled >인증완료</button></div>
     </div>
     <hr>
<!--     //이메일 입력//-->
      <div class="row mb-3">
       <label for="mail" class="col-sm-3 col-form-label">이메일<span class="must">*</span></label>
       <div class="col-sm-4">
         <input type="email" class="form-control" id="mail" placeholder="sample@company.com">
       </div>
       <div class="col-sm-2 startMail" style=""><button type="button" class="varify" @click="varifyEmail" >이메일 인증</button></div>
       <div class="col-sm-2 resetMail" style="display:none;"><button type="button" class="varify" @click="resetEmail" >다시 입력</button></div>
       <button type="button" class="varify Run_E_Modal" data-bs-toggle="modal" data-bs-target="#Email_Varidation" style="display: none;">모달 실행</button>

     </div>
<!--     //비밀번호 입력//-->
      <div class="row mb-3">
        <label for="pass" class="col-sm-3 col-form-label">비밀번호<span class="must">*</span></label>
        <div class="col-sm-4">
          <input v-model="chkpass" type="password" class="form-control" id="pass" >
        </div>
        <div class="col" style="font-size: 13px;">8자리이상 영문자, 숫자, 특수문자</div>
      </div>
<!--     //비밀번호 재입력//-->
      <div class="row mb-3">
        <label for="repass" class="col-sm-3 col-form-label">비밀번호[재입력]<span class="must">*</span></label>
        <div class="col-sm-4">
          <input v-model="repass" type="password" class="form-control" id="repass" placeholder="">
        </div>
        <div class="col" style="font-size: 13px;" v-if="passShow===1"></div>
        <div class="col" style="font-size: 13px; color:darkred;" v-if="passShow===2">비밀번호가 일치하지 않습니다.</div>
        <div class="col" style="font-size: 13px; color:green;" v-if="passShow===3">비밀번호가 일치합니다.</div>
      </div>




<!--     //닉네임 입력//-->
      <div class="row mb-3">
       <label for="nickName" class="col-sm-3 col-form-label">닉네임<span class="must">*</span></label>
       <div class="col-sm-3">
         <input type="text" class="form-control" id="nickName" placeholder="닉네임을 입력하세요.">
       </div>
       <div class="col-sm-2 startNick" style=""><button type="button" class="varify" @click="varifyNick" >증복확인</button></div>
       <div class="col-sm-2 resetNick" style="display:none;"><button type="button" class="varify" @click="resetNick" >다시 입력</button></div>

     </div>

      <hr>
<!--     //회사명 입력//-->
      <div class="row mb-3">
        <label for="userComp" class="col-sm-3 col-form-label">회사명</label>
        <div class="col-sm-4">
          <input type="text" class="form-control" id="userComp" placeholder="">
        </div>
      </div>
<!--     //직책 입력//-->
      <div class="row mb-3">
        <label for="userJob" class="col-sm-3 col-form-label">직책</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" id="userJob" placeholder="">
        </div>
      </div>


     <!--     //이용약관//-->
       <div class="row mb-3">
         <label for="userJob" class="col-sm-3 col-form-label">이용약관</label>
         <div class="col-sm-12 policyBox">
           <div class="policy_Header">
              PA Soft 회원 이용약관 동의<br>
             <span class="P_SubTitle">AutoClosing 서비스 이용을 위하여 하단의 이용약관 및 정보이용에 동의 바랍니다.</span>
           </div>
           <div class="row mb-3 policy_contents" style="border-bottom:1px solid #d9d9d9; padding-bottom:15px;">
             <div class="form-check col-sm-10">
               <input class="form-check-input chkpol" type="checkbox" value="" id="policy01">
               <label class="form-check-label" for="policy01">
                 만 14세 이상입니다. <span class="must2">(필수)</span>
               </label>
             </div>


             <div class="form-check col-sm-10">
               <input class="form-check-input chkpol" type="checkbox" value="" id="policy02">
               <label class="form-check-label" for="policy02">
                 이용약관 동의 <span class="must2">(필수)</span>
               </label>
             </div>
<!--             <div class="original col-sm-2" data-bs-toggle="modal" data-bs-target="#policy01md">원문보기</div>-->
             <button id="Pol_01" class="original col-sm-2 checkpol" type="button" data-bs-toggle="modal" data-bs-target="#policies01" >원문 보기</button>
             <div class="form-check col-sm-10">
               <input class="form-check-input chkpol" type="checkbox" value="" id="policy03">
               <label class="form-check-label" for="policy03">
                 개인(신용)정보 수집 및 이용동의 <span class="must2">(필수)</span>
               </label>
             </div>
<!--             <div id="Pol_01" class="original col-sm-2">원문보기</div>-->

             <button id="Pol_02" class="original col-sm-2 checkpol" type="button" data-bs-toggle="modal" data-bs-target="#policies02" >원문 보기</button>

             <div class="form-check col-sm-10">
               <input class="form-check-input chkpol" type="checkbox" value="" id="policy04">
               <label class="form-check-label" for="policy04">
                 기업회원(신용)정보 수집 및 이용 동의 <span class="must2">(필수)</span>
               </label>
             </div>
<!--             <div id="Pol_02" class="original col-sm-2">원문보기</div>-->
             <button id="Pol_03" class="original col-sm-2 checkpol" type="button" data-bs-toggle="modal" data-bs-target="#policies03" >원문 보기</button>

             <div class="form-check col-sm-10">
               <input class="form-check-input chkpol" v-model="Marketing" type="checkbox" value="" id="policy06">
               <label class="form-check-label" for="policy06">
                 마케팅 정보 수신 동의 <span class="must2" style="color: #0e2238">(선택)</span>
               </label>
             </div>
<!--             <div id="Pol_04" class="original col-sm-2">원문보기</div>-->
             <button id="Pol_04" class="original col-sm-2 checkpol" type="button" data-bs-toggle="modal" data-bs-target="#policies04" >원문 보기</button>
           </div>
           <div class="policy_footer">
             <div class="form-check">
               <input class="form-check-input" type="checkbox" value="" id="CheckAll" style="margin-top: 6px" @click="onChk" >
               <label class="form-check-label" for="CheckAll">
                 전체 동의하기
               </label>
             </div>
           </div>
         </div>
       </div>

<!--     //가입버튼//-->
       <div style="margin-bottom: 200px;">
         <button type="submit" id="confirm_btn" class="btn btn-primary varify" @click="submitUser">가입하기</button>
       </div>

     <!--     이메일 인증 모달폼-->
     <div class="modal fade" id="Email_Varidation" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
       <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
         <div class="modal-content">
           <div class="modal-header">
             <h1 class="modal-title fs-5" id="exampleModalLabel">E-MAIL VALIDATION</h1>
             <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div class="modal-body" style="padding-top: 0px;">
              <form autocomplete="off">
                <h4>PA Soft 사용자 이메일 인증</h4>
                <div class="varid_Title">
                  <span>- 본인 인증을 위하여 입력하신 이메일로 인증코드가 발송되었습니다.</span><br>
                  <span>- PA Soft에서는 이메일을 사용자 ID로 사용합니다.</span><br>
                  <span>- 인증번호는 최대 5분간 유효 합니다.</span>
                </div>
                <div class="input_Valid">
                  <div class="row mb-3">
                    <div class="col-sm-7">
                      <input type="text" class="form-control" id="email_valid" placeholder="인증번호를 입력하세요.">
                    </div>
                    <div class="col-sm-4" style="align-items: center; display: flex;">
                      <div id="re_confirm1" class="original col-sm-2" style="background-color: #0e2238; margin-right: 10px;" @click="confirmEmail">확   인</div>
                      <span>제한시간 </span> <span style="color:darkred; margin-left: 5px;">{{TimerStr}}</span></div>
                  </div>
                </div>

              </form>

           </div>

           <div class="modal-footer">
             <div class="valid_Footer">
               <div id="cancel1" class="original col-sm-2" data-bs-dismiss="modal" style="margin-left: 10px; ">취   소</div>
               <div id="re_vaild1" class="original col-sm-2" @click="resend_varify" >재발송</div>

             </div>
           </div>
         </div>
       </div>
     </div>

     <!--     이용약관 모달폼-->
     <Policies/>

     <!--    본인인증 완료 모달-->
     <button type="button" class="varify A_Modal" data-bs-toggle="modal" data-bs-target="#user_Varidation_Comp" style="display: none;">인증완료</button>

     <div class="modal fade" id="user_Varidation_Comp"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
       <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
         <div class="modal-content" style="width: 500px; margin: auto;">
           <div class="modal-header">
             <h1 class="modal-title fs-5" id="exampleModalLabel">Authorization</h1>
             <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div class="modal-body" style="padding-top: 0px;">
             <form autocomplete="off">
               <h4>사용자 정보 인증</h4>
               <div class="varid_Title">
                 <span>- 사용자 본인 인증이 완료 되었습니다. </span><br>

               </div>

             </form>

           </div>


         </div>
       </div>
     </div>

     <!--    본인인증 실패 모달-->
     <button type="button" class="varify F_Modal" data-bs-toggle="modal" data-bs-target="#user_Varidation_Fail" style="display: none;">인증완료</button>

     <div class="modal fade" id="user_Varidation_Fail" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
       <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
         <div class="modal-content" style="width: 500px; margin: auto;">
           <div class="modal-header">
             <h1 class="modal-title fs-5" id="exampleModalLabel">Authorization</h1>
             <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
           </div>
           <div class="modal-body" style="padding-top: 0px;">
             <form autocomplete="off">
               <h4>사용자 정보 인증</h4>
               <div class="varid_Title">
                 <span>- 이미 인증된 사용자정보 입니다. 로그인 화면으로 돌아갑니다.</span><br>
               </div>
               <div class="input_Valid">
                 <div class="col-sm-12" style="align-items: center; display: flex; justify-content: center">
                   <div id="re_confirm1" class="original col-sm-2" style="background-color: #0e2238; margin-right: 10px;" @click="goLogin">확   인</div>
                 </div>
               </div>

             </form>

           </div>


         </div>
       </div>
     </div>

   </form>
 </div>




</template>

<script>
import Policies from "@/components/CommonPage/accounts/policies.vue";


export default {
  data() {
    return {
      chkpass:"",
      repass:"",
      passShow:1,
      // 타이머 구현
      Timer: null,
      TimeCounter: 300,
      TimerStr: "05:00",
      //가입조건 패스
      mailPass: false,
      nickPass: false,
      policyChk: false,
      Marketing: false,
      mobileAuth:false,
      //본인인증 정보
      userName:'',
      phoneNumber:'',
      infoCode: ''
    }
  },
  components: {
    Policies
  },

  watch:{

    repass: function () {
      this.varifyPassWord()
    },

  },
  mounted() {
    this.chkpolicy()
    this.onChkall()
  },
  methods: {


    varifySelf() {
      this.childWindow =
          window.open('/api/nicecheck/getAuth/CHECK_INFO', 'startAuth',
              'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no,' +
              ' status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

      window.addEventListener('message', this.Message.bind(this));
    },

    //본인인증에서 받은 메세지를 부모윈도우에서 출력//
    Message(event) {
      if (event.data.type === 'authResult'){
        const t =this

         if (event.data.result) {
           // 미인증 사용자
           t.mobileAuth = true
           t.userName = event.data.userName
           t.phoneNumber = event.data.userPhone
           t.infoCode = event.data.infoCode
           $('.A_Modal').click()
           t.closeWindow()

        }
        else{
           // 이미 인증된 사용자
           $('.F_Modal').click()
           t.closeWindow()
        }
      }
    },
    goLogin(){
      this.mobileAuth = false
      window.location.href = '/login'
    },
    closeWindow(){
      const targetWindow = window.open('', 'startAuth');
      if (targetWindow && !targetWindow.closed) {
        targetWindow.close();
      }
    },
    // 이메일 형식체크 모달 실행//
    varifyEmail() {
      const validateEmail = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/
      if (!validateEmail.test($("#mail").val()) || !$("#mail").val()) {
        alert("이메일 형식이 맞지 않습니다.")
        return
      }
      else {

        this.runTimer()

        const url = '/account/reqEmailValidation'
        const utoken =$store.state.token != null ? true : false;
        const getdata ={
          "emailAddr": $('#mail').val()
        }

        $api.postApiData (url,utoken,successStat,errorStat,getdata);
        function successStat (res) {
          $('.Run_E_Modal').click()
        }
        function errorStat (error) {
          alert(error.response.data.message);
        }

      }
    },
    //이메일 인증시작//
    confirmEmail() {

      const t =this
      const url = '/account/confirmEmailCode'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "emailAddr": $('#mail').val(),
        "validationCode": $('#email_valid').val()
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        if(res.status===200) {
          alert('인증이 완료 되었습니다.')
          $('.E_modalClose').click();

          $('#mail').attr('disabled','true');

          $('.startMail').css('display','none');
          $('.resetMail').css('display','block');
          t.mailPass = true
        }
      }
      function errorStat (error) {
        alert('인증문자가 맞지 않습니다')
      }


    },

    //인증번호 재송부
    resend_varify(){

      const t =this
      const url = '/account/reqEmailValidation'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "emailAddr": $('#mail').val(),
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        alert("인증번호가 재발송 되었습니다.")
        //타이머 초기화
        t.runTimer()
      }
      function errorStat (error) {
        alert(error.response.data.message);
      }

    },

    //이메일 재인증//
    resetEmail() {
      $('.startMail').css('display','block');
      $('.resetMail').css('display','none');
      $('#mail').removeAttr('disabled');
      this.mailPass = false
    },

    //비밀번호 재입력 체크//
    varifyPassWord() {
      if(this.repass === "") {

        this.passShow = 1;
      }
      else if(this.repass === this.chkpass) {

        this.passShow = 3;
      }
      else{
      this.passShow = 2;}
    },


    //닉네임 중복체크//
    varifyNick() {

      const t=this
      const url = '/account/nickNameValid'
      const nickvd = $('#nickName').val();
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        nickName:nickvd
      }
      if(nickvd === '' || nickvd === null){ alert("닉네임을 입력해 주세요.")}
      else {
        $api.getApiData(url, utoken, successStat, errorStat, getparam);

        function successStat(res) {
          if (res.status === 200) {
            alert("사용가능한 닉네임입니다")
            t.nickPass = true
            $('#nickName').attr('disabled', 'true');
            $('.startNick').css('display', 'none');
            $('.resetNick').css('display', 'block');
          }
        }

        function errorStat(error) {
          alert("이미 사용중인 닉네임입니다.")
        }
      }
    },

    resetNick() {
      this.nickPass = false
      $('.startNick').css('display','block');
      $('.resetNick').css('display','none');
      $('#nickName').removeAttr('disabled');
    },


    // 타이머 구현
    runTimer: function() {
      if(this.Timer != null){
        this.timerStop(this.Timer);
        this.Timer = null;
      }
      this.Timer = this.timerStart();
    },

    timerStart: function() {
      // 1초에 한번씩 start 호출
      this.TimeCounter = 300;
      var interval = setInterval(() => {
        this.TimeCounter--; //1초씩 감소
        this.TimerStr = this.prettyTime();
        if (this.TimeCounter <= 0) this.timerStop(interval);
      }, 1000);
      return interval;
    },
    timerStop: function(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
    },
    prettyTime: function() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
          minutes.toString().padStart(2, "0") +
          ":" +
          secondes.toString().padStart(2, "0")
      );
    },


    // 이용약관 체크 확인

    onChkall() {
      $(".form-check").on("click", "#CheckAll", function () {
        var checked = $(this).is(":checked");

        if (checked) {
          $('.chkpol').prop("checked", true);
        } else {
          $('.chkpol').prop("checked", false);
        }
      });
    },
    chkpolicy() {
      $(".form-check").on("click", ".chkpol", function() {
        var is_checked = true;

        $(".form-check .chkpol").each(function(){
          is_checked = is_checked && $(this).is(":checked");
        });

        $("#CheckAll").prop("checked", is_checked);
      });

    },

    submitUser(event) {
      event.preventDefault();
      if(this.mobileAuth != true) {
        alert("핸드폰 본인 인증을 진행해 주십시오.")
      }
      else{
        if(this.mailPass != true) {
          alert("이메일 인증을 진행해 주십시오.")
        }
        else{
          const validatePass = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/
            if (!validatePass.test(this.chkpass) || !this.chkpass ) {
              alert("비밀번호 형식이 맞지 않습니다. 8자리 이상의 영문,숫자,문자 조합으로 입력해 주세요.")
              return
            }
            else{
              if( this.repass === this.chkpass ) {
                if ($("#nickName").val() === "" || this.nickPass === false) {
                  alert("닉네임을 확인해 주십시오")
                }
                else{
                  if($('#userName').val() != "" && $('#phone').val() != ""){
                    if($("#policy01").is(":checked")==true&&$("#policy02").is(":checked")&&$("#policy03").is(":checked")&&$("#policy04").is(":checked")==true){


                      const url = '/account/signUp'
                      const utoken =$store.state.token != null ? true : false;
                      const t = this
                      const getdata ={
                        "mailAddr": $('#mail').val(),
                        "password": $('#pass').val(),
                        "ownerName": $('#userName').val(),
                        "company": $('#userComp').val(),
                        "position": $('#userJob').val(),
                        "phone": $('#phone').val(),
                        "nickName": $('#nickName').val(),
                        "termMarketingConfirm": this.Marketing,
                        "infoCode": this.infoCode                    }

                      $api.postApiData (url,utoken,successStat,errorStat,getdata);
                      function successStat (res) {
                        alert("회원가입이 완료 되었습니다. 로그인 화면으로 돌아갑니다.");

                        t.$router.push({
                          path: '/login'
                        })
                      }
                      function errorStat (error) {
                        alert('회원 가입에 실패하였습니다.')
                      }


                    }
                    else{
                      alert("이용약관에 체크해 주세요")
                    }
                  }
                  else{
                    alert("필수입력 정보를 확인해 주세요.")
                  }
                }
              }
              else{
                 alert("비밀번호를 재확인 해 주십시오")
              }

            }
        }
      }
    }
  }
}
</script>

<style scoped>
.modal {
  --bs-modal-width:800px;
  --bs-modal-border-radius: 4px;
}

.modal-title{
  font-family: 'Pretendard-Regular';
}

.modal-header {
  height: 40px;
}
.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}
.varid_Title{
  margin-bottom: 21px;
  margin-top: 15px;
  font-size: 13px;
}
#email_valid {
  border: 1px solid #7e7e7e;
}

.policies{
  width:100%;
  height:500px;
  background-color: #62778d;
}
.addForm {
  width: 100%;
  margin: 80px auto;
  align-items: center;
  height: auto;
  justify-content: center;
  display: flex;
  background-color: ;

}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #10263f;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom : 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /*margin-bottom:30px;*/
}

.form-control:focus {
  color: #212529;
  background-color: rgb(4 37 64 / 8%);
  border-bottom : 1px solid #ced4da;
  outline: 0;
  box-shadow: none;
}

.form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1 !important;
  color: #a7a7a7 !important;
}

.must {
  color: red;
}

.must2 {
  color: red;
  font-size: 11px;
}

.varify {
  width:100px;
  height:27px;
  border:none;
  color:white;
  background-color: #10263f;
  font-size:13px;
  margin-top: 3px;
}

.varify_disable {
  width:100px;
  height:27px;
  border:none;
  color:white;
  background-color: #adadad;
  font-size:13px;
  margin-top: 3px;
}


.policyBox {
  margin-top:20px;
  margin-bottom:20px;
  padding-top: 5px;
  padding-bottom: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  line-height: 33px;
}
.policy_Header{
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
}
.policy_footer{
  font-weight: bold;
  font-size: 14px;
  line-height: 25px;
  float:right;
  padding-right:27px;
}
.P_SubTitle{
 font-weight: lighter;
  font-size: 13px;

}

.form-check {
  font-size: 13px;
  padding-left: 50px;

}
.original {
  width: 100px;
  height: 25px;
  border: none;
  color: white;
  background-color: #959595;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  float:right;
  cursor:pointer;
}

.form-check-input {
  margin: 10px 10px;
}

#confirm_btn {
  width:160px;
  height:35px;
  font-size: 18px;
  margin-left: 50%;
  position: relative;
  left:-80px;
  margin-top:15px;
  border-radius: 0px;

}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  /*background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;*/
  color: fieldtext !important;
}
</style>