<template>

  <div class="reset_PW_Body">


    <form class="reset_form" autocomplete="off" onsubmit="return false">
      <router-link to="/" class="">
        <img :src="logo" alt="Logo" width="153" height="" class="d-inline-block align-text-top">
      </router-link>
      <div class="Cmt_Title" v-if="changePass === true">
        <span>계정정보 확인을 완료하였습니다.</span><br>
        <span>변경할 비밀번호를 입력해 주세요.</span><br>
        <div class="col" style="font-size: 13px; color:darkred">8자리이상 영문자, 숫자, 특수문자</div>
      </div>
      <div class="Cmt_Title" v-if="changePass === false">
        <span>인증정보가 유효하지 않습니다.</span><br>
        <span>다시 한번 변경신청을 하여 새로운 인증링크를 메일로 확인하세요.</span><br>
      </div>
      <br>
      <div class="input_Valid" v-if="changePass === true">
        <div class="userinfo">
          <span>이름: {{username}}</span><br>
          <span>이메일: {{mailadd}}</span><br>
          <span>회사: {{company}}</span><br>
          <span>닉네임: {{nick}}</span><br>
        </div>
        <div class="row mb-3">
          <div class="col-sm-8" style="margin:auto;">
            <input v-model="chkpass" type="password" class="form-control" id="pass_E_valid" placeholder="변경할 비밀번호 입력">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-sm-8" style="margin:auto;">
            <input v-model="repass" type="password" class="form-control" id="pass_E_revalid" placeholder="비밀번호 재입력">
          </div>
        </div>

        <!--     //비밀번호 재입력//-->
        <div class="row mb-3" style="text-align: center">

          <div class="col" style="font-size: 13px;" v-if="passShow===1"></div>
          <div class="col" style="font-size: 13px; color:darkred;" v-if="passShow===2">비밀번호가 일치하지 않습니다.</div>
          <div class="col" style="font-size: 13px; color:green;" v-if="passShow===3">비밀번호가 일치합니다.</div>
        </div>

      </div>
      <br>


      <div class="footer" v-if="changePass === true">
        <div class="valid_Footer" >
          <div id="confirm_Report" type="button" class="modal_btn original" style="background-color:#0e2238; font-size: 15px" @click="changePW">비밀번호 변경</div>
        </div>
      </div>

      <div class="footer" v-if="changePass === false">
        <div class="valid_Footer">
         <div id="confirm_Report" type="button" class="modal_btn original" style="font-size: 15px" @click="goLogin">로그인 페이지로</div>
        </div>
      </div>
    </form>

    <!--비번 변경 확인 모달폼-->
    <button type="button" id="changePW_btn" class="btn btn-primary detail_btn" data-bs-toggle="modal" data-bs-target="#change_PW" style="display: none">변경</button>

    <div class="modal fade" id="change_PW" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Password Confirm</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            비밀번호를 변경합니다. 이대로 진행하시겠습니까?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-primary" @click="changeFinalPw">변경</button>
          </div>
        </div>
      </div>
    </div>



  </div>

</template>

<script>
export default {
  data(){
    return{
      logo: require('/src/components/CommonPage/images/AutoClosing02.png'),
      tempKey: '',
      username: '',
      mailadd: '',
      company: '',
      position: '',
      nick: '',
      phone: '',
      changePass:false,
      chkpass:"",
      repass:"",
      passShow:1,
    }
  },
  watch: {

    repass: function () {
      this.varifyPassWord()
    },
  },

  mounted(){
    this.tempKey = this.$route.query.resultKey
    this.confirmDnResult()

  },
  methods:{
//비밀번호 재입력 체크//
    varifyPassWord() {
      if(this.repass === "") {

        this.passShow = 1;
      }
      else if(this.repass === this.chkpass) {

        this.passShow = 3;
      }
      else{
        this.passShow = 2;}
    },

    confirmDnResult(){
        const key = this.tempKey
        const t=this
        const url = '/account/validateChangePassword/'+ key
        const utoken =$store.state.token != null ? true : false;
        $api.getApiData(url,utoken,successStat,errorStat);

        function successStat (res) {
          if(res.status===200) {
            t.changePass=true;
            const Status= res.data.data
            t.username= Status.ownerName;
            t.mailadd= Status.emailAddr;
            t.company= Status.company;
            t.position= Status.position;
            t.nick= Status.nickName;
            t.phone= Status.phoneNumber;

          }

        }
        function errorStat (error) {
          t.changePass=false;
          alert("유저 정보를 가져오지 못했습니다. 다시 확인해 주세요.")

        }

    },

    goLogin(){
      window.location.href="/login"
    },

    changePW() {
      if( this.repass === this.chkpass ) {
        const validatePass = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/
        if (!validatePass.test($('#pass_E_valid').val()) || !$('#pass_E_valid').val()) {
          alert("비밀번호 형식이 맞지 않습니다. 8자리 이상의 영문,숫자,문자 조합으로 입력해 주세요.")
          return
        } else {
          // 마지막 확인 팝업 띄우기
          $('#changePW_btn').click()

        }
      }
      else{
        alert("비밀번호를 재확인 해 주세요.")
      }
    },

    changeFinalPw() {
      const url = '/account/accountPwdChange'
      const utoken = $store.state.token != null ? true : false;
      const getdata = {
        "newPwd": $('#pass_E_valid').val(),
        "resultKey": this.tempKey
      }

      $api.postApiData(url, utoken, successStat, errorStat, getdata);

      function successStat(res) {
        if (res.status === 200) {
          alert('비밀번호 변경이 완료 되었습니다. 다시 로그인해 주세요.')
          window.location.href = "/login"
        }
      }

      function errorStat(error) {
        alert(error.response.data.message);
      }

    }

  }
}
</script>

<style scoped>
.userinfo{
  padding: 10px;
  font-size: 13px;
  border:1px solid #697d978a;
  border-radius: 5px;
  width:300px;
  margin:10px auto;
}

.modal-header
{
  padding: 0px 9px 6px 9px;;

}

.modal-footer {
  padding:3px;
}

.original {
  font-family: Pretendard-Regular;
  width: 120px;
  height: 40px;
  border: none;
  border-radius:5px;
  color: white;
  background-color: #959595;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  margin: 0px auto;
  cursor:pointer;
  padding-top:8px;
}

.reset_PW_Body {
  display: flex;
  width:50%;
  height:100vh;
  margin:auto;
  align-items: center;
  font-family: Pretendard-Regular;
}

.Cmt_Title{
  margin-top:20px;
}

.reset_form {
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding:20px;
  width:500px;
  margin:auto;
  box-shadow: 5px 5px 5px #dddddd
;
}




</style>

