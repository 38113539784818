<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        리스회계처리 자동화<sup><i> v1.0.3 </i></sup>
      </div>
      <div id="sub_title">
        작성된 리스 양식의 세부내용을 확인 하여 산출물을 작성합니다.
      </div>
    </div>

    <div class="row">

      <div class="Main_Contents col-sm-4 mb-3 mb-sm-0" >
      <div style="margin-bottom:60px;">아래 [리스자료 UPLOAD] 버튼을 클릭하여, 작성완료된 리스 양식 자료를 UPLOAD 합니다.<br>
        발행 완료 된 산출물은 등록된 USER MAIL 계정으로 송부 됩니다. </div>
      <div class="l_menus" v-if="workingStatus==='SUCCESS'||workingStatus==='IDLE'|| workingStatus==='ERROR' || workingStatus==='FAIL' || workingStatus===''">
        <div>
          <form method="POST" enctype="multipart/form-data" id="leaseForm">

            <label for="ls-file" id="up_btn" class="guidebox">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" id="up_btn_icon" fill="currentColor" class="icons" viewBox="0 0 16 16">
                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
              </svg><p>리스자료 UPLOAD</p>
            </label>

            <input class="file" type="file" id="ls-file" accept=".xls,.xlsx" name="multipartFile" @change="upload">
          </form>
        </div>

      </div>
      <div class="l_menus" v-if="workingStatus==='PENDING'|| workingStatus==='PROCESSING'">
        <div>
            <label for="ls-file" id="up_btn" class="guidebox disabled">
              <img class="loading" src="../images/loading.gif"><p>작업을 처리중 입니다...</p>
            </label>

            <input class="file" type="file" id="ls-file" accept=".xls,.xlsx" name="multipartFile" @change="upload" disabled>

        </div>
      </div>

      <!--산출 성공-->
      <div class="finished_run"  v-if="workingStatus==='SUCCESS'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 최종 작업 내역입니다.</span>
        </div>
        <div class="run_detail">
          작업 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
          ||<a class="run_btn" :href="lastOutputFile">산출 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*입력파일과 산출파일은 최종 작업 시 입력 및 전달된 파일과 동일 합니다.</span>
        </div>
      </div>

<!--      산출실패-->
      <div class="finished_run"  v-if="workingStatus==='PENDING'|| workingStatus==='PROCESSING'" >
        <div>
          <span>현재 <span style="color:#0e427c">{{user}}</span>님의 작업중인 내용이 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*작업이 끝난 후 다시 업로드 가능합니다. 새로고침 하여 주십시오.</span>
        </div>
        <button class="btn btn-success resetbtn" @click="reset_form">작업 새로고침</button>
      </div>

      <!--      실행에러 01 -->
      <div class="finished_run"  v-if="workingStatus==='ERROR'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 최종 작업 중 오류가 발생하였습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*자료를 재업로드하여 결과를 확인하세요.</span>
        </div>

      </div>

      <!--      실행에러 02 -->
      <div class="finished_run"  v-if="workingStatus==='FAIL'" >
        <div>
          <span><span style="color:#0e427c">{{user}}</span>님의 입력 내용에 오류가 있습니다.</span>
        </div>
        <div class="run_detail">
          요청 일자: <span style="color:#0e2238">{{workingDate}}</span>
          <a class="run_btn" :href="lastUploadFile">입력 파일</a>
        </div>
        <div style="color:#0c83e1;" >
          <span>*입력내용을 확인하시고 재업로드하여 결과를 확인하세요.</span>
        </div>

      </div>


      <div class="l_menus guide_pdf" style="margin-bottom: 10px;">
        <router-link to="/svcs/leasef" >
          <p>양식자료 PAGE</p>
        </router-link>
      </div>
      새로 작성한 양식자료가 필요한 경우 리스회계 양식 PAGE에서
      <br>다시 다운로드 하실 수 있습니다.<span style="font-size: 12px; font-weight: 800;">(클릭하여 PAGE 이동)</span>
    </div>
      <div class="Samples col-sm-5 mb-3 mb-sm-0">

<!--          <Carousel>-->
<!--            <Slide v-for="slide in slides" :key="slide">-->
<!--              <div class="carousel__item">-->
<!--                <img class="slideImg" :src="slide.img" /></div>-->
<!--            </Slide>-->

<!--            <template #addons>-->
<!--              <Navigation />-->
<!--              <Pagination />-->
<!--            </template>-->
<!--          </Carousel>-->

      </div>
    </div>



  </div>
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'


export default {
  name: "lease_run",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data(){
    return {
       finished : true,
       workingDate:'',
       workingStatus:'',
       lastUploadFile:'',
       lastOutputFile:'',
       user: $store.state.user_log,
       slides: [{img:require("@/components/CommonPage/images/samples/test.gif")},{img:require("@/components/CommonPage/images/loading.gif")},],
    }
  },
  mounted(){
    if($store.state.token != null){
    this.getUserWork()
    }
    else{
      this.workingStatus='';
    }
  },

  methods:{

    getUserWork: function() {
      const t=this
      const url = '/lease/leaseStatus'
      const utoken =$store.state.token != null ? true : false;
      $api.getApiData(url,utoken,successStat,errorStat);

      function successStat (res) {
        const Status= res.data.data
        t.workingStatus = Status.taskStatus;
        t.workingDate = Status.createDate;
        t.lastUploadFile = Status.inputFilePath;
        t.lastOutputFile = Status.outputFilePath;
      }
      function errorStat (error) {
        alert("유저 작업 정보를 가져오지 못했습니다. 페이지를 새로고침 해 주세요.")

      }

    },

    upload: function () {
      this.$gtag.event('LeaseRun(U)Click',{
        event_category: 'Clicks',
        event_label: 'Counts',
        value: 'Lease Upload'
      })
      let a = localStorage.getItem("access-token")
      if( a != null){
        let b = localStorage.getItem("mobileAuth")
        const t = this
        if(b === "true"){
          t.uploadLease()
        }
        else{
          alert("본인 인증이 필요한 기능입니다. [마이페이지]에서 본인인증을 완료해 주세요");
          window.location.href="/accpage/myinfo"
        }
      }
      else{ alert("로그인 정보가 없습니다. 로그인 페이지로 이동합니다.");
        window.location.href="/login"}

    },

    uploadLease(){
      var form = $('#leaseForm')[0];
      var formdata = new FormData(form);
      var file = formdata.get('multipartFile')
      // console.log(file)

      const t =this
      const url = '/lease/uploadLeaseFile'
      const utoken =$store.state.token != null ? true : false;
      const inputdata ={
        "inputFile": file
      }

      $api.uploadApiData (url,utoken,successStat,errorStat,inputdata);
      function successStat (res) {

        alert('파일을 업로드 하였습니다.');
        /*--폼데이터 클리어--*/
        $('#leaseForm')[0].reset();
        /*--새로고침--*/
        window.location.href="/svcs/leaser"

      }
      function errorStat (error) {

        alert('파일 업로드를 실패 하였습니다.');
        /*--새로고침--*/
        window.location.href="/svcs/leaser"

      }
    },

    reset_form: function() {
      window.location.href="/svcs/leaser"

    }
  }
}
</script>

<style scoped>
.carousel{
  width:100%;
  height:100%;
}
.carousel__item {
  min-height: 200px;
  width: 100%;
  height: 380px;
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
  width: 90%;
  border: 1px solid #d1d1d1;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}


.guide_pdf{
  margin-bottom:10px;
  background-color: #0e2238;
  width:120px; height:30px;
  text-align:center;
  padding-top: 4px;
  color:white;
  border-radius: 20px;
  cursor: pointer;
}

.guide_pdf p:hover {
  color:#c6cfdd !important;
}
.l_menus{
  margin-bottom: 65px;
  font-size: 14px;
}


.icons{
  display: flex;
  width: 53px;
  background-color: #fff;
  height: 44px;
  padding: 6px;
  margin: auto 8px;
  border-radius: 25px;
}

.guidebox{
  display: flex;
  background-color: #dee2e6;
  width:265px;
  height:55px;
  border-radius: 35px;
  margin-bottom: 10px;
}

.disabled{
  display: flex;
  background-color: #cbd1d7;
  width:265px;
  height:55px;
  border-radius: 35px;
  margin-bottom: 10px;
}

.guidebox p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 20px;

}

.disabled p {
  font-weight: 800;
  font-size: 13px;
  margin: auto 20px;
  color: #29486b;
}

.file {display: none;}
#leaseForm label{
  cursor:pointer;
}

.finished_run {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  width: 90%;
  margin: 40px 0px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 7px;
}

.l_menus a{
  text-decoration: none;
}

.resetbtn {
  font-size: 14px;
  border-radius: 25px;
  width: 61px;
  padding-top: 3px;
  margin-left: 2px;
  background-color: transparent;
  color: black;
  padding-top: 7px;
  margin-top: 7px;
  width: 130px;
}

.run_detail{
  color:grey;
}
.run_btn{
  font-size: 12px;
  background-color: #0e2238;
  color: white;
  text-decoration: none;
  padding: 5px 15px;
  border-radius: 30px;
  line-height: 45px;
  margin-left: 10px;
  margin-right: 6px;
}

.loading{
  width: 49px;
  height: 46px;
  border-radius: 42px;
  padding: 1px;
  margin-top: 5px;
  margin-left: 7px;
}

</style>