<template>
  <div>
    <div class="S_Contents">
      <div id="S_Sidebar">
        <C_Side/>
      </div>
      <div id="S_M_Contens" class="container-fluid" style="padding-top: 25px;">
        <router-view/>
      </div>
      <div id="mo_M_footer">
        <p class="mo_HeadLine">커뮤니티</p>
        <ul class="mo_ul_style">
          <li class="mo_menu_link">

            <a to="" class="mo_r_link" href="#BoardCollapse" data-bs-toggle="collapsed" role="button" aria-expanded="true" aria-controls="BoardCollapse" style="color: #cde3fd;" disabled>
              <svg  style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
              <span>고객소통공간</span>
            </a>
            <div class="collapsed" id="BoardCollapse" style="">
              <ul class="sub_items">
                <li class="nav-item">
                  <router-link to="/cmnt/kakaoch" >
                    상담센터
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/cmnt/nlist/1" >
                    공지사항
                  </router-link>
                </li>
<!--                <li class="nav-item">-->
<!--                  <router-link to="/cmnt/list/1" >-->
<!--                    자유게시판-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                  <router-link to="/cmnt/qnalist/1" >-->
<!--                    질문게시판-->
<!--                  </router-link>-->
<!--                </li>-->
                <!--              <li class="nav-item">-->
                <!--                <router-link to="/cmnt/faq" >-->
                <!--                  자주묻는 질문-->
                <!--                </router-link>-->
                <!--              </li>-->
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import C_Side from "@/components/CommonPage/SubMenus/Comm_Side.vue"

export default {
  name: "QnABOARD",

  components : {C_Side},

};

</script>

<style scoped>

.d-flex{
  margin: 0px 23%;
}
.row{
  margin-left: 0;
  margin-right: 0;
}

#S_Sidebar{
  border-right: 1px solid var(--bs-navbar-vertical-border-color);
  bottom: 0;
  display: block;
  max-width: 18.25rem;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  color:white;
  background: linear-gradient( to bottom,  #212121, #434040 );
  /*background-color: #0e2238 !important;*/

  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  --bs-navbar-padding-x: 1.875rem;
  --bs-navbar-padding-y: 1.875rem;
  --bs-navbar-brand-padding-y: 0.375rem;
  --bs-navbar-brand-margin-end: 0;
  --bs-navbar-brand-font-size: 1rem;
  --bs-navbar-nav-link-padding-x: 1.875rem;
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 0;
  --bs-navbar-toggler-font-size: 1rem;
  --bs-navbar-toggler-border-radius: 0;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: none;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

#S_M_Contens {
  margin-top: 10px;
  margin-left: 24.25rem;
  width:70%;
  margin-bottom: 200px;

}

#mo_M_footer {
  display:none;
  background: linear-gradient( to bottom,  #212121, #434040 );
}
</style>