<template>
  <div style="padding-bottom: 200px;">
    <form class="mo_form">
      <br>
      <!--제목줄라인 및 본문-->
      <div id="detail_header" class="row">
        <div class="col-12">{{title}}</div>
        <div class="col"
             style="color:#21406a;
             font-size:13px;
             font-weight: 200;
             border-top: #0e2238 1px solid;
             border-bottom: #0e2238 1px solid;
             margin:10px 0px 0px 0px;
             padding: 5px 0px 5px 10px;"> {{user_name}}   |   {{created_date}}
        </div>
      </div>
      <div id="detail_info" class="row">
        <div class="row">
          <textarea id="a_context" ref="myTextarea"
                    rows=15 min-rows="25"
                    disabled
                    style="resize:none;
                     height: 350px;
                     border-left: none;
                     border-right: none;
                     padding-top: 20px;
                     padding-left:20px;
                     font-size: 14px;
                     line-height: 22px;"
                    v-model="context"></textarea>
        </div>


      </div>

      <!--댓글 유무 표시 수정/삭제 버튼-->
      <div id="detail_btn" class="row">
        <div class="totalcmt col-6 start-0 " style="text-align: left; padding-left: 20px; font-size: 13px;">댓글 <span style="color:#0d6efd">{{ totalCmtRows }}</span>개 </div>
        <div id="board_btn" class="col-6" v-if="this.author === this.logedId" >
          <button type="button" id="detail_update" class="btn btn-primary detail_btn" @click="updateData">수정</button>
          <button type="button" id="detail_delete" class="btn btn-primary detail_btn" data-bs-toggle="modal" data-bs-target="#user_Delete">삭제</button>
        </div>
      </div>

      <!--게시글 삭제 모달폼-->
      <div class="modal fade" id="user_Delete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">User Delete</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              게시물을 삭제합니다. 정말 삭제하시겠습니까?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
              <button type="button" class="btn btn-primary" @click="deleteData">삭제</button>
            </div>
          </div>
        </div>
      </div>

      <!--댓글 작성-->
      <div class="reply_create row" v-if="this.logedId != 0">
        <div class="col-11 cmtbody" style="width:80%; margin-right:10px;">
          <b-form-textarea
              v-model="cmt_context"
              placeholder="내용을 입력해 주세요"
              rows="2"
              max-rows="15"
              oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
              style="resize: none; font-size:13px; padding: 8px; max-height: 100px; margin-right: 10px;">
          </b-form-textarea>
          <div style="margin-bottom: 0px; text-align: end; font-size:11px;">
            ({{cmt_Length}}/ 150)
          </div>
        </div>
        <div class="col savecmt" style="width:10%">
          <b-button @click="uploadComment()">등록</b-button>
        </div>
      </div>

      <!--댓글 목록-->
      <div class="reply_area" v-if="this.cmtdatajson.length != 0">
        <!--댓글 페이징-->
        <b-pagination v-model="currentCmtPage" :total-rows="totalCmtRows" :per-page="perCmtPage" align="center" @click="callCmtPage"></b-pagination>

        <div class="comment_item row" v-for="(row, index) in cmtdatajson" :key="row.seqNm" >
          <div class="col-8 cmt_text">{{row.authorNickName}} | {{row.createDate}}
            <div class="revbtn" v-if="row.authorId === this.logedId">
              <button type="button" class="cmtrevbtn" v-on:click="updateComment(index)" v-if="modify=== false" >수정</button>
              <span v-if="modify === false">|</span>
              <button type="button" class="cmtrevbtn" v-on:click="updateCmtComplete(row.seqNm, row.updatedBody || row.body )" v-if="modify === true && index === selectedCommentIndex">수정완료</button>
              <button v-if="modify === false" type="button" class="cmtrevbtn" v-on:click="deleteComment(row.seqNm)" >삭제</button>
            </div>
          </div>

          <textarea class="cmt_text_body" :value="row.body" disabled style="resize: none;"></textarea>
          <textarea class="cmt_modify_body col-12"  rows="2" v-if="modify === true && index === selectedCommentIndex"  :value="row.updatedBody || row.updatedBody=== '' ? row.updatedBody : row.body" @input="updateCommentValue(index, $event.target.value)"></textarea>

        </div>

        <!--댓글 페이징-->
        <b-pagination v-model="currentCmtPage" :total-rows="totalCmtRows" :per-page="perCmtPage" align="center" @click="callCmtPage"
                      style="border-top: 1px solid #0e223857; padding-top: 10px;"></b-pagination>

      </div>


    </form>

  </div>
</template>

<script>

export default {
  name: "Q_Detail",

  data() {

    return {
      contentid:Number(this.$route.params.contentId),
      contentSeqNm: Number(this.$route.query.seqNm),
      title: '',
      context: '',
      user_name: '',
      created_date: '',
      updated_date: '',
      author:Number,
      logedId: Number($store.state.userId),
      logedUser:$store.state.user_log,
      a_height: Number,

      // 신규코멘트 정보
      cmt_context:'',
      cmt_name:$store.state.userId != null ? '로그인유저': '로그인이 필요합니다',
      modify: false,
      selectedCommentIndex: -1,
      cmt_Length:0,

      // 코멘트 목록 정보
      cmtdatajson: [],
      currentCmtPage: 1 ,
      totalCmtRows:Number,
      perCmtPage:10
    };
  },

  watch:{
    context: function(){
      this.$nextTick(() => {
        this.articleHeightUpdate()
      });

    },
    cmt_context:function(){
      this.cmt_Length = this.cmt_context.length
      if(this.cmt_Length ===150){
        alert("댓글 내용은 150자를 넘을 수 없습니다.")
      }
    }


  },

  mounted() {
    this.currentdate()
    this.getarticle()
    this.getComment()
    // history.replaceState({}, null, location.pathname);

  },

  methods: {
    articleHeightUpdate(){
      const c = $('#a_context').prop('scrollHeight')

      $('#a_context').css('height',c + 70);
    },

    getarticle() {

      const t =this
      const url = '/board/common/readArticle'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "seqNm": this.contentSeqNm
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const a = res.data.data
        t.title = a.title
        t.context = a.body
        t.user_name = a.authorNickName
        t.created_date = a.createDate
        t.author = Number(a.authorId)
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    getComment() {
      const t =this
      const url = '/board/common/getCommentList'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "postSeq": this.contentSeqNm,
        "page": this.currentCmtPage-1,
        "size": this.perCmtPage,
        "sort": "string"
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        t.totalCmtRows = res.data.totalElements
        const c = res.data.content
        t.cmtdatajson = c
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    },

    callCmtPage(){

      const t =this
      const a = this.currentCmtPage
      const url = '/board/common/getCommentList'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "postSeq": this.contentSeqNm,
        "page": a-1,
        "size": this.perCmtPage,
        "sort": "string"
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const c = res.data.content

        t.cmtdatajson = c
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    },

    currentdate(){
      let timezoneOffset = new Date().getTimezoneOffset() * 60000;
      let timezoneDate = new Date(Date.now() - timezoneOffset);
      // console.log(timezoneDate.toISOString());
      this.created_at = timezoneDate.toISOString().substring(0, 10);
    },

    updateData() {

      this.$router.push({
        name: 'Q_Create',
        params: {
          contentId: this.contentid
        },
        query: {
          seqNm: this.contentSeqNm
        }
      });

    },

    deleteData() {

      const t =this
      const url = '/board/common/deleteArticle'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "seqNm": this.contentSeqNm
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        t.$router.push({
          path: "/cmnt/qnalist/1"
        });
      }
      function errorStat (error) {
        alert("삭제에 실패하였습니다.")
      }


      // 데이터 삭제
      $(".modal-backdrop").css("display","none");


    },

    uploadComment() { // 저장

      const t =this
      const url = '/board/common/createComment'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "postSeq": this.contentSeqNm,
        "body": this.cmt_context
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        t.getComment()
        t.cmt_context = ''
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    updateCommentValue(index, value) {
      this.cmtdatajson[index].updatedBody = value;
    },
    updateComment(index) {
      this.modify = true;
      this.selectedCommentIndex = index;

    },
    updateCmtComplete (seqNm, updatedBody) {
      const t =this
      const url = '/board/common/updateComment'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "seqNm": seqNm,
        "body": updatedBody
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        t.getComment()
        t.modify = false;
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    },

    deleteComment(seqNm) {
      const t =this
      const url = '/board/common/deleteComment'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "seqNm": seqNm
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        t.getComment()
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    }



  },

};
</script>
<style scoped>

form {
  width:70%
}
/*--삭제 창 스타일 --*/

.modal-dialog-scrollable .modal-content {
  max-height: 70%;
  overflow: hidden;
}

.modal-header
{
  padding: 6px 15px 12px 15px;

}

#Point_Window th {
  text-align: center;
}

.w3-table-all td {
  text-align: center;
}

/*--디테일 페이지--*/

.row{
  margin-left: 0;
  margin-right: 0;
}

li{
  list-style: none;
  line-height: 45px;
}

.detail_btn {
  margin: 0px 10px;
  height: 27px;
  padding: 1px 10px;
  background-color: #00264a;
  border: 0px;
}


#detail_header{
  width:100%;
  align-items: center;
  padding: 10px 0px;
  font-weight: 700;
  font-size: 17px;
}

#detail_info{
  width:100%;
  height:auto;
  padding: 5px 0px 15px 0px;
  border-bottom: 2px solid #00264a;
}

#detail_btn{
  width:100%;
  align-items: center;
  padding: 10px 0px;
  text-align: right;
}

#Excepted_user .form-check {
  display: inline-block;
}
.reply_create{
  border-top: 1px solid #00264a;
  padding-top: 20px;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #00264a;
}

.reply_area {
  padding-top: 20px;
  /*padding-bottom: 150px;*/
}
.savecmt {
  justify-items: center!important;
}

.pagination{
  --bs-pagination-border-color: none;
  --bs-pagination-font-size: 12px
}

.comment_item {
  margin-bottom: 0px;
  border-top: #0e223857 1px solid ;
  padding-top:10px
}

.cmtrevbtn {
  border: none;
  background-color: #00264a;
  color: #ffffff;
  border-radius: 5px;
  font-size: 12px;
  height: 19px;
  font-weight: 100;
}

.cmt_text_body {
  border:none;
  background-color: transparent;

  /*!*border-bottom: #0e223857 1px solid ;*!*/
  margin:5px 0px 5px 0px;
  padding-top:10px;
  font-size: 13px;
}

.cmt_text {
  font-size: 12px;
  color:#0c83e1;
  font-weight: 800;
}
.revbtn {
  text-align: start;
  display:inline;
  margin-left: 10px;
}

.cmt_modify_body {
  width:95%;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  resize: none;
  font-size:13px;
  padding: 8px;
  max-height: 100px;
}
</style>