<template>
  <div>
    <div id="menu_List" style="margin-top: 61px;">
      <p class="HeadLine" >AutoClosing 서비스</p>
      <ul class="ul_style">
<!--        리스자동화 메뉴-->
        <li class="menu_link">
          <a to="/svcs/leaseg" class="r_link" href="#BoardCollapse" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="BoardCollapse"  >
            <svg  style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>
            </svg>
<!--            <svg  viewBox="0 0 24 24" class="nav-link-icon"  style="margin-top: -4px;" height="14" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M22.91,6.953,12.7,1.672a1.543,1.543,0,0,0-1.416,0L1.076,6.953a.615.615,0,0,0,0,1.094l10.209,5.281a1.543,1.543,0,0,0,1.416,0L22.91,8.047a.616.616,0,0,0,0-1.094Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M.758,12.75l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,12.75" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M.758,17.25l10.527,5.078a1.543,1.543,0,0,0,1.416,0L23.258,17.25" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>-->
            <span>리스 자동화
             <svg class="betaIcon" fill="#b6c8dd" width="30px"  viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>beta-solid</title>
                      <polygon points="25.8,18 27.5,18 26.7,15.8" class="clr-i-solid clr-i-solid-path-1"></polygon><path d="M10.4,17.5c-0.1,0-0.2,0-0.3,0H8.5V19l1.6,0c0.4,0.1,0.8-0.2,0.9-0.6C11.1,18,10.8,17.6,10.4,17.5z" class="clr-i-solid clr-i-solid-path-2"></path><path d="M10.7,15.8c0-0.4-0.3-0.7-0.8-0.7H8.5v1.3h1.4C10.4,16.5,10.7,16.2,10.7,15.8z" class="clr-i-solid clr-i-solid-path-3"></path><path d="M33.1,9h-30c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h4v4c0,0.4,0.2,0.8,0.6,0.9C7.8,30,7.9,30,8.1,30c0.3,0,0.5-0.1,0.7-0.3
                        l4.7-4.7h19.6c0.6,0,1-0.4,1-1V10C34.1,9.4,33.6,9,33.1,9z M10.4,20.1c-0.1,0-0.1,0-0.2,0H7.2v-6h3c0.9-0.1,1.7,0.5,1.8,1.4
                        c0,0,0,0.1,0,0.1c0,0.6-0.3,1.1-0.8,1.3c0.6,0.2,1.1,0.8,1.1,1.5C12.2,19.4,11.4,20.1,10.4,20.1z M17.9,15.2h-3.3v1.2h3v1.2h-3v1.3
                        h3.3v1.2h-4.6v-6h4.6V15.2z M21.7,20.1h-1.3v-4.8h-1.9v-1.2h5v1.2h-1.8V20.1z M28.4,20.1l-0.4-1h-2.7l-0.4,1h-1.4l2.4-6h1.4l2.5,6
                        H28.4z" class="clr-i-solid clr-i-solid-path-4"></path>
                      <rect x="0" y="0" width="30"  fill-opacity="0"/>
                    </svg>
            </span>
          </a>
          <div class="collapse show" id="BoardCollapse" style="">
            <ul class="sub_items">
              <li class="nav-item">
                <router-link to="/svcs/leaseg" >
                  사용자 가이드
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/svcs/leasef" >
                  리스양식 산출<sup><i> v1.0.3 </i></sup>
                </router-link>
              </li>
              <li class="nav-item" >
                <router-link to="/svcs/leaser" >
                  리스회계처리 자동화
                </router-link>
              </li>
            </ul>
          </div>
        </li>
        <!--DSD footing 메뉴-->
        <li class="menu_link">
          <a to="/svcs/dsdfg" class="r_link" href="#BoardCollapse2" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="BoardCollapse" >
            <svg style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-medical" viewBox="0 0 16 16">
              <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
            </svg>
            <span>DSD Footing 자동화
            <svg class="betaIcon" fill="#b6c8dd" width="30px" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <title>beta-solid</title>
                      <polygon points="25.8,18 27.5,18 26.7,15.8" class="clr-i-solid clr-i-solid-path-1"></polygon><path d="M10.4,17.5c-0.1,0-0.2,0-0.3,0H8.5V19l1.6,0c0.4,0.1,0.8-0.2,0.9-0.6C11.1,18,10.8,17.6,10.4,17.5z" class="clr-i-solid clr-i-solid-path-2"></path><path d="M10.7,15.8c0-0.4-0.3-0.7-0.8-0.7H8.5v1.3h1.4C10.4,16.5,10.7,16.2,10.7,15.8z" class="clr-i-solid clr-i-solid-path-3"></path><path d="M33.1,9h-30c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h4v4c0,0.4,0.2,0.8,0.6,0.9C7.8,30,7.9,30,8.1,30c0.3,0,0.5-0.1,0.7-0.3
                        l4.7-4.7h19.6c0.6,0,1-0.4,1-1V10C34.1,9.4,33.6,9,33.1,9z M10.4,20.1c-0.1,0-0.1,0-0.2,0H7.2v-6h3c0.9-0.1,1.7,0.5,1.8,1.4
                        c0,0,0,0.1,0,0.1c0,0.6-0.3,1.1-0.8,1.3c0.6,0.2,1.1,0.8,1.1,1.5C12.2,19.4,11.4,20.1,10.4,20.1z M17.9,15.2h-3.3v1.2h3v1.2h-3v1.3
                        h3.3v1.2h-4.6v-6h4.6V15.2z M21.7,20.1h-1.3v-4.8h-1.9v-1.2h5v1.2h-1.8V20.1z M28.4,20.1l-0.4-1h-2.7l-0.4,1h-1.4l2.4-6h1.4l2.5,6
                        H28.4z" class="clr-i-solid clr-i-solid-path-4"></path>
                      <rect x="0" y="0" width="30" fill-opacity="0"/>
                    </svg>
            </span>
          </a>
          <div class="collapse show" id="BoardCollapse2" style="">
            <ul class="sub_items">
              <li class="nav-item">
<!--                <router-link to="/svcs/const" style="padding: 9px 46px;">-->
<!--                  사용자 가이드-->
<!--                </router-link>-->

                <router-link to="/svcs/dsdfg" style="padding: 9px 46px;">
                  사용자 가이드
                </router-link>
              </li>

              <li class="nav-item">

<!--                <router-link to="/svcs/const" style="padding: 9px 46px;">-->
<!--                  감사/검토보고서 검토-->
<!--                </router-link>-->

                <router-link to="/svcs/dsdfr" style="padding: 9px 46px;">
                  감사/검토보고서 Footing
                </router-link>
              </li>

              <li class="nav-item">

                <!--                <router-link to="/svcs/const" style="padding: 9px 46px;">-->
                <!--                  사업보고서 검토-->
                <!--                </router-link>-->

                <router-link to="/svcs/dsdfr2" style="padding: 9px 46px;">
                  사업보고서 Footing
                </router-link>
              </li>

<!--              <li class="nav-item">-->

<!--                <router-link to="/svcs/const" style="padding: 9px 46px;">-->
<!--                보고서 간 대사 (준비중..)-->
<!--                </router-link>-->

<!--&lt;!&ndash;                <router-link to="/svcs/dsdfr" style="padding: 9px 46px;">&ndash;&gt;-->
<!--&lt;!&ndash;                  보고서 간 대사 (준비중..)&ndash;&gt;-->
<!--&lt;!&ndash;                </router-link>&ndash;&gt;-->
<!--              </li>-->

            </ul>
          </div>
        </li>

        <!--DSD Auto 메뉴-->
<!--        <li class="menu_link">-->
<!--          <a to="/svcs/dsdatg" class="r_link" href="#BoardCollapse3" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="BoardCollapse" >-->
<!--            <svg style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-medical" viewBox="0 0 16 16">-->
<!--              <path d="M7.5 5.5a.5.5 0 0 0-1 0v.634l-.549-.317a.5.5 0 1 0-.5.866L6 7l-.549.317a.5.5 0 1 0 .5.866l.549-.317V8.5a.5.5 0 1 0 1 0v-.634l.549.317a.5.5 0 1 0 .5-.866L8 7l.549-.317a.5.5 0 1 0-.5-.866l-.549.317V5.5zm-2 4.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 2a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>-->
<!--              <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>-->
<!--            </svg>-->
<!--            <span>DSD 작성 자동화-->
<!--            <svg class="betaIcon" fill="#b6c8dd" width="30px" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">-->
<!--                      <title>beta-solid</title>-->
<!--                      <polygon points="25.8,18 27.5,18 26.7,15.8" class="clr-i-solid clr-i-solid-path-1"></polygon><path d="M10.4,17.5c-0.1,0-0.2,0-0.3,0H8.5V19l1.6,0c0.4,0.1,0.8-0.2,0.9-0.6C11.1,18,10.8,17.6,10.4,17.5z" class="clr-i-solid clr-i-solid-path-2"></path><path d="M10.7,15.8c0-0.4-0.3-0.7-0.8-0.7H8.5v1.3h1.4C10.4,16.5,10.7,16.2,10.7,15.8z" class="clr-i-solid clr-i-solid-path-3"></path><path d="M33.1,9h-30c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1h4v4c0,0.4,0.2,0.8,0.6,0.9C7.8,30,7.9,30,8.1,30c0.3,0,0.5-0.1,0.7-0.3-->
<!--                        l4.7-4.7h19.6c0.6,0,1-0.4,1-1V10C34.1,9.4,33.6,9,33.1,9z M10.4,20.1c-0.1,0-0.1,0-0.2,0H7.2v-6h3c0.9-0.1,1.7,0.5,1.8,1.4-->
<!--                        c0,0,0,0.1,0,0.1c0,0.6-0.3,1.1-0.8,1.3c0.6,0.2,1.1,0.8,1.1,1.5C12.2,19.4,11.4,20.1,10.4,20.1z M17.9,15.2h-3.3v1.2h3v1.2h-3v1.3-->
<!--                        h3.3v1.2h-4.6v-6h4.6V15.2z M21.7,20.1h-1.3v-4.8h-1.9v-1.2h5v1.2h-1.8V20.1z M28.4,20.1l-0.4-1h-2.7l-0.4,1h-1.4l2.4-6h1.4l2.5,6-->
<!--                        H28.4z" class="clr-i-solid clr-i-solid-path-4"></path>-->
<!--                      <rect x="0" y="0" width="30" fill-opacity="0"/>-->
<!--                    </svg>-->
<!--            </span>-->
<!--          </a>-->
<!--          <div class="collapse show" id="BoardCollapse3" style="">-->
<!--            <ul class="sub_items">-->
<!--              <li class="nav-item">-->
<!--                &lt;!&ndash;                <router-link to="/svcs/const" style="padding: 9px 46px;">&ndash;&gt;-->
<!--                &lt;!&ndash;                  사용자 가이드&ndash;&gt;-->
<!--                &lt;!&ndash;                </router-link>&ndash;&gt;-->

<!--                <router-link to="/svcs/dsdatg" style="padding: 9px 46px;">-->
<!--                  사용자 가이드-->
<!--                </router-link>-->
<!--              </li>-->

<!--              <li class="nav-item">-->

<!--                &lt;!&ndash;                <router-link to="/svcs/const" style="padding: 9px 46px;">&ndash;&gt;-->
<!--                &lt;!&ndash;                  감사/검토보고서 검토&ndash;&gt;-->
<!--                &lt;!&ndash;                </router-link>&ndash;&gt;-->

<!--                <router-link to="/svcs/dsdatr" style="padding: 9px 46px;">-->
<!--                  DSD Auto-->
<!--                </router-link>-->
<!--              </li>-->



<!--            </ul>-->
<!--          </div>-->
<!--        </li>-->

      </ul>


    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },
  mounted() {

  },
  methods: {
    // mclick: function (event) {
    //   $('.nav-link').removeClass('active');
    //   $(event.target).addClass('active');},

    logout: () => {
      localStorage.removeItem('access-token');
      alert("로그아웃 되었습니다.")
      window.location.href='/login'
    }
  }
}
</script>


<style scoped>

.HeadLine{
  margin-left: -11px;
  font-size: 17px;
  font-family: pretendard-regular;
  color: white;
  font-weight: 400;
}

.r_link {
  font-family: pretendard-regular;
  color: #b6c8dd !important;
  font-weight: 400 !important;
  font-size: 14px;
  margin-bottom: -14px;
}

.ul_style {
  margin-top: 0;
  box-sizing: border-box;
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.90625rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  list-style: none;
  padding-left: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  flex-direction: column;
  margin-left: calc(var(--bs-navbar-padding-x)*-2.5);
  margin-right: calc(var(--bs-navbar-padding-x)*-1);

}

.ul_style li {
  position: relative;
  box-sizing: border-box;
  display: list-item;
  text-align: -webkit-match-parent;
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.90625rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  list-style: none;
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
}

.menu_link {
  margin-bottom: 5px;
}


.ul_style li a {
  align-items: center;
  border-bottom-right-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  display: block;
  //color: var(--bs-nav-link-color);
  //font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  text-decoration: none;
  margin-right: 1.875rem;
  /*padding-left: var(--bs-navbar-nav-link-padding-x);*/
  padding-left: 20px;
  font-size: 14px;
  line-height: 30px;
  color:grey;

}

.ul_style li a:hover {color:#7ea2cb !important; }

.sub_items {

  padding-left: 0px;
}

.sub_items a {
  padding: 3px 0px 3px 60px !important;
}

svg {
  margin-right: 0.625rem;
}

.router-link-active {
  color:white !important;
  //background-color: #434a52;
  transition: 0.1s;
}

</style>


