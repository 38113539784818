<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        공지사항
      </div>
      <div id="sub_title">
        AutoClosing의 최신 소식을 알려드립니다.
      </div>
    </div>
    <!--    <b-button @click="writeContent">글쓰기</b-button>-->
    <div class="board_main" >
      <table class="w3-table-all pa_table" >
        <thead>
        <tr>
          <th>번호</th>
          <th >제목</th>
          <th>작성자</th>
          <th>작성일</th>
          <th>조회수</th>

        </tr>
        </thead>

        <tbody>
        <tr v-for="(row, index) in comarray " :key="row.seqNm">
          <td>{{ row.rowNumber }}</td>
          <td class="td_style"><a v-on:click="rowClick($event, row)" :index="row.seqNm">{{ row.title }}
            <span v-if="row.commentCount > 0" style="font-size: 11px; color:#0d6efd">[{{row.commentCount}}]</span></a></td>
          <td>{{ row.authorNickName }}</td>
          <td>{{ row.createDate }}</td>
          <td>{{ row.viewCount }}</td>

        </tr>
        </tbody>


      </table>

      <table class="w3-table-all pa_table_mo" >
        <thead>
        <tr>
          <th>번호</th>
          <th >제목</th>
          <th>작성자</th>
          <th>조회수</th>

        </tr>
        </thead>

        <tbody style="border-bottom: 1px solid">
        <tr v-for="(row, index) in comarray " :key="row.seqNm">
          <td>{{ row.rowNumber }}</td>
          <td class="td_style"><a v-on:click="rowClick($event, row)" :index="row.seqNm">{{ row.title }}
            <span v-if="row.commentCount > 0" style="font-size: 11px; color:#0d6efd">[{{row.commentCount}}]</span></a></td>
          <td>{{ row.authorNickName }}</td>
          <td>{{ row.viewCount }}</td>

        </tr>
        </tbody>


      </table>

      <div id="detail_btn" class="row" style="margin-bottom: 20px;">
        <div id="Create_qna" v-if="role === 'ROLE_MASTER'" >
          <button type="submit" class="btn btn-primary detail_btn" @click="writeqna">글쓰기</button>
        </div>
      </div>
      <b-pagination v-model="currentPage" :total-rows="totalrows" :per-page="perPage" align="center" @click="callPage"></b-pagination>

      <form class="d-flex" role="search" >
        <select class="Searchbar form-select" aria-label="Default select example" @change="SearchOptionValue" style="width:25%">
          <option value="1" selected>제목</option>
          <option value="2">작성자</option>
          <option value="3">내용</option>
        </select>
        <input id="searchContext" class="form-control me-2" type="search" placeholder="Search" aria-label="Search" style="width:50%; margin-left:20px">
        <button class="search detail_btn btn-outline-success" type="submit" @click="searchArticle">Search</button>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: "QnAList",
  data() {

    return {
      currentPage: this.$route.params.page >1 ? Number(this.$route.params.page) : 1 ,// 현재 페이지
      perPage: 10, // 페이지당 보여줄 갯수
      totalrows: 1,
      comarray: [],
      type: 'NOTICE',
      role: $store.state.userRole, //로그인 사용자의 권한 구분
      searchOption:'TITLE',
      searchResult: false
    };
  },


  methods: {

    getlist() {
      const t =this
      const pages = this.currentPage
      const url = '/board/common/getArticleList'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "page": pages-1,
        "size": this.perPage,
        "type": this.type
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const comdata = res.data

        t.comarray = comdata.content
        t.totalrows = comdata.totalElements
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },

    callPage(){
      const pages = this.currentPage
      window.location.href = '/cmnt/nlist/'+ pages

    },

    rowClick(e,row) {

      this.$router.push({
        name: 'N_Detail',
        params: {
          contentId: row.rowNumber
        },
        query: {
          seqNm: row.seqNm
        }
      });

    },


    writeqna() {
      this.$router.push({
        path: `/cmnt/ncreate`
      });
    },

    SearchOptionValue() {

        const selectedValue = $('.Searchbar').val();
        if (selectedValue === "1") {
          this.searchOption = "TITLE";
        } else if (selectedValue === "2") {
          this.searchOption = "NICKNAME";
        } else if (selectedValue === "3") {
          this.searchOption = "BODY";
        } else {
          this.searchOption = "";
        }
        // 선택된 값에 따라 할당된 문자열 출력
      },

    searchArticle(event){
      event.preventDefault();

      const sc = $('#searchContext').val();
      const cat = this.searchOption;

      this.$router.push({
        name: 'Notice_List_S',
        params: {
          page: 1
        },
        query: {
          "category": cat,
            "keyword": sc
        }
      });

    }

  },
  mounted(){
    if(this.$route.params.page >1) {
      this.currentPage = Number(this.$route.params.page)
      // console.log("페이지 2 이상 currentpage="+this.currentPage)
      this.getlist()
    }
    else {
      // console.log("페이지 1 실행")
      this.getlist()
    }

  },
  computed: {

  }
};

</script>

<style scoped>
.d-flex{
  margin: 0px 23%;
}
.w3-table td, .w3-table th, .w3-table-all td, .w3-table-all th {
  font-size:14px;
  line-height: 16px;
  text-align: center;
}
td a {
  cursor:pointer;
}
.td_style {
  width: 60% ;
  text-align: left !important;
  padding-left: 20px;
}
.form-select {
 font-size: 13px;
  height: 33px;
}
.form-control {
  font-size: 13px;
  height: 33px;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
  /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
  /* box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); */
}

.detail_btn {
  margin: 0px 10px;
  height: 27px;
  padding: 1px 10px;
  background-color: #00264a;
  border: 0px;
}

.search {
  border-radius: 5px;
  color: white;
  font-size: 13px;
  height: 33px;}

.pa_table {
  margin-bottom: 20px;
}

.pa_table_mo {
  display: none;
  margin-bottom: 20px;
}

.board_main {
  width: 70%
}

</style>