<template>
<div>
  <div id="whole" class="container d-lg-flex align-items-lg-center content-space-t-3 content-space-lg-0 min-vh-lg-100" >
    <div class="p404_window">
     <div class="p404_title">
       404 ERROR
     </div>
      <div class="p404_desc">
        죄송합니다. 요청하신 페이지를 찾을 수 없습니다. 메인화면의 메뉴를 확인해 주세요.
      </div>
      <button class="p404_btn"><router-link to="/">홈으로</router-link></button>
    </div>
  </div>
  <div id="M_footer" >
    Copyright 2023. PA Soft Co., Ltd. all rights reserved.
  </div>
</div>
</template>

<script>
export default {
  data(){
    return{

    }
  }
}
</script>

<style scoped>
#whole {
  background: url("../CommonPage/images/login-resized03.jpg") no-repeat;
  background-position-y: 13%;
  background-size: cover;
  max-width: 100% !important;
  height:94vh;
  margin-top: 0px;
  margin-left: 0;
  margin-right: 0;
//background-color:#526374;
  border-bottom: 1px solid #002d5c29;
}

#M_footer {
  margin-top: 35px;
  font-size: 12px;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #92a3bb;
  position: fixed;
  top: 90%;
  margin-left: auto;
  width: 100%;
}

.p404_window{
  margin:auto;
  text-align: center;
    }

.p404_title{
  font-size: 60px;
  color:white;
  margin-bottom: 10px;
}
.p404_desc{
  font-size: 13px;
  color:white;
  margin-bottom: 40px;
}

.p404_btn{

  height: 35px !important;
  padding: 0px 23px !important;
  color: rgb(14, 34, 56) !important;
  border: 1px white !important;
  border-radius: 20px !important;

}

.p404_btn a {
  text-decoration: none;
}


</style>