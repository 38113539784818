<template>
  <div>
    <div id="board_title" class="row" v-if="updateMode===false">
      <div id="main_title">
        신규 게시물
      </div>
      <div id="sub_title">
        공지사항을 작성해 주세요.
      </div>
    </div>
<!--    게시물 수정 시-->
    <div id="board_title" class="row" v-if="updateMode===true">
      <div id="main_title">
        게시물 수정
      </div>
      <div id="sub_title">
        게시글 내용을 수정합니다.
      </div>
    </div>

    <div class="row articleTitle" style="margin-bottom: 20px;">
    <span style="font-weight: bold; font-size: 14px;">제목</span><input class="titleBody" v-model="subject" style="font-size:14px;" placeholder="제목을 입력해주세요.(필수)">
    </div>
    <div class="row articleBody">
      <span style="font-weight: bold; font-size: 14px;">내용</span>
      <b-form-textarea
        class="d_context"
        v-model="context"
        placeholder="내용을 입력해 주세요"
        rows="15"
        max-rows="25"
        oninput='this.style.height = ""; this.style.height = this.scrollHeight + "px"'
        style="resize: none; line-height:22px; font-size:14px; padding: 15px; max-height: 500px; margin-right: 10px; box-shadow: none;">
      </b-form-textarea>
    <div id="test_cnt" style=" text-align: end; padding-right:10px;">
      ({{conLength}}/ 1000)
    </div>
    </div>

    <div class="creat_btn_row" >
      <b-button @click="updateMode ? updateContent() : uploadContent()">저장</b-button>&nbsp;
      <b-button @click="cancel">취소</b-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'N_Create',
  data() {
    // props: ['contentId', 'seqNm']
    return {
      seqnm: Number(this.$route.query.seqNm),
      subject: '',
      context: '',
      conLength: 0,
      type:'NOTICE',
      category: "공지사항",
      created_at: "",
      updated_at: null,
      updateObject: null,
      updateMode: this.$route.params.contentId > 0 ? true : false,
    }
  },
  watch:{

    context: function() {
      this.conLength = this.context.length
      if(this.conLength ===1000){
        alert("게시물 내용은 1000자를 넘을 수 없습니다.")
      }
    }
  },

  mounted() {
    this.currentdate()
  },

  created() {
    if(this.$route.params.contentId > 0) {

      const t =this
      const url = '/board/common/readArticle'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "seqNm": this.seqnm
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const a = res.data.data
        t.subject = a.title
        t.context = a.body
        t.conLength = a.body.length
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    }
  },
  methods: {

    currentdate(){
      let timezoneOffset = new Date().getTimezoneOffset() * 60000;
      let timezoneDate = new Date(Date.now() - timezoneOffset);
      // console.log(timezoneDate.toISOString());
      this.created_at = timezoneDate.toISOString().substring(0, 10);
    },

    uploadContent() { // 저장

      if($(".titleBody").val() ===''){
        alert("제목을 입력해 주세요. (필수 입력사항 입니다)")
      }
      else{
        const t =this
        const url = '/board/common/createArticle'
        const utoken =$store.state.token != null ? true : false;
        const getdata ={
          "title": this.subject,
          "body": this.context,
          "type": this.type
        }

        $api.postApiData (url,utoken,successStat,errorStat,getdata);
        function successStat (res) {
          t.$router.push({
            path: '/cmnt/nlist/1'
          })
        }
        function errorStat (error) {
          alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
        }

      }


    },

    updateContent() { // 수정

      if($(".titleBody").val() ===''){
        alert("제목을 입력해 주세요. (필수 입력사항 입니다)")
      }
      else{
        const t =this
        const url = '/board/common/updateArticle'
        const utoken =$store.state.token != null ? true : false;
        const getdata ={
          "seqNm": this.seqnm,
          "title": this.subject,
          "body": this.context
        }

        $api.postApiData (url,utoken,successStat,errorStat,getdata);
        function successStat (res) {
          t.$router.push({
            name: 'N_Detail',
            params: {
              contentId: t.$route.params.contentId
            },
            query: {
              seqNm: t.seqnm
            }
          });
        }
        function errorStat (error) {
          alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
        }

      }

    },


    cancel() {
      if(this.$route.params.contentId > 0) {
        this.$router.push({
          name: 'N_Detail',
          params: {
            contentId: this.$route.params.contentId
          },
          query: {
            seqNm: this.seqnm
          }
        });
      }
      else {
        this.$router.push({
          path: `/cmnt/nlist/1`
        });
      }

      // this.$router.push({
      //   path: '/cmnt/detail/' + this.seqnm
      // })
    }
  }
}
</script>
<style scoped>
.articleTitle,.articleBody {
  width:70%}

.articleTitle input
{ border-radius: 3px;
  border:1px solid #ced4da}

.articleTitle input:focus-visible
{ outline:1px solid #86b7fe }

.creat_btn_row {
  width:70%; text-align: center; padding-right:10px; margin-top: 20px;
}
</style>
