<template>
  <div>
    <div class="S_Contents">
      <div id="S_Sidebar">
        <Account_Side/>
      </div>
      <div id="S_M_Contens" class="container-fluid" style="padding-top: 25px;">
        <router-view/>
      </div>
      <div id="mo_M_footer">
        <p class="mo_HeadLine">마이페이지</p>
        <ul class="mo_ul_style">
          <li class="mo_menu_link">

            <a to="" class="mo_r_link" href="#BoardCollapse" data-bs-toggle="collapsed" role="button" aria-expanded="true" aria-controls="BoardCollapse" style="color: #cde3fd;" disabled>
              <svg style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill-gear" viewBox="0 0 16 16">
                <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4Zm9.886-3.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z"/>
              </svg>
              <span>회원정보 관리</span>
            </a>
            <div class="collapsed" id="BoardCollapse" style="">
              <ul class="sub_items">
                <li class="nav-item">
                  <router-link to="/accpage/myinfo" >
                    회원 정보
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link to="/accpage/history/1" >
                    이용 내역
                  </router-link>
                </li>
                <!--              <li class="nav-item">-->
                <!--                <router-link to="/accpage/qna" >-->
                <!--                  고객 센터-->
                <!--                </router-link>-->
                <!--              </li>-->
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import Account_Side from "@/components/CommonPage/accounts/account_Side"

export default {
  name: "MyInformation",

  components : {Account_Side},

};
</script>

<style scoped>

.d-flex{
  margin: 0px 23%;
}
.row{
  margin-left: 0;
  margin-right: 0;
}

#S_Sidebar{
  border-right: 1px solid var(--bs-navbar-vertical-border-color);
  bottom: 0;
  display: block;
  max-width: 18.25rem;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  color:white;
  background: linear-gradient( to bottom, #212121, #434040);
  /*background-color: #0e2238 !important;*/

  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  --bs-navbar-padding-x: 1.875rem;
  --bs-navbar-padding-y: 1.875rem;
  --bs-navbar-brand-padding-y: 0.375rem;
  --bs-navbar-brand-margin-end: 0;
  --bs-navbar-brand-font-size: 1rem;
  --bs-navbar-nav-link-padding-x: 1.875rem;
  --bs-navbar-toggler-padding-y: 0;
  --bs-navbar-toggler-padding-x: 0;
  --bs-navbar-toggler-font-size: 1rem;
  --bs-navbar-toggler-border-radius: 0;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: none;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

#S_M_Contens {
  margin-top: 10px;
  margin-left: 24.25rem;
  width:70%;
}

#mo_M_footer {
  display:none;
  background: linear-gradient( to bottom,  #212121, #434040 );
}
</style>