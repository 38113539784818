<template>
  <div>
    <div id="board_title" class="row">
      <div id="main_title">
        상담센터
      </div>
      <div id="sub_title">
        AutoClosing의 카카오톡 채널을 추가하여 회계이슈를 상담해 보세요.
      </div>
    </div>
    <div>
      <div class="intro">
        <img width="95px" src="@/components/CommonPage/images/kakao01.png">
        <span class="t_word">AutoClosing 카카오톡 상담센터</span>
      </div>
      <div class="ch_desc">
        AutoClosing에서는 서비스에 대한 질의 및 회계처리에 대한 질의사항을 제휴 회계사가 직접 답변드릴 수 있도록 카카오톡 상담센터를 오픈하였습니다.<br><br>

        AutoClosing의 서비스 또는 AutoClosing 서비스와 관련이 없는 어떠한 회계처리 문의도 상관없으니 많은 이용 바랍니다.<br><br>

        <div class="caution">
          (*) AutoClosing의 회계처리 답변은 귀사의 회계처리의 의사결정을 보장하는 것이 아닙니다. 질의의 내용에 모든 것을 담아 말씀주시지 못할 수 있기 때문에,
        추가적인 정황이나 구체적인 상황에 따라서 결과가 달라질 수 있습니다. 답변은 의사결정에 참고로만 부탁드립니다.
          또한, AutoClosing은 회계처리 답변으로 인한 의사결정에 어떠한 책임도 지지 않음을 사전에 고지드립니다.</div>
      </div>

      <div class="add_ch">
        <div style="font-weight: bold; margin-bottom: 20px;">[채널 추가 방법 1]</div>
        <div class="method">
          카카오톡 채널 URL 링크 클릭:
          <span><a href="http://pf.kakao.com/_FFgpG/chat">http://pf.kakao.com/_FFgpG/chat</a></span>
        </div>
        <div style="font-weight: bold; margin-top: 40px; margin-bottom: 20px;">[채널 추가 방법 2]</div>
        <div class="method" style="text-align: center">
          <img class="kakaoch01" style="margin-right:30px;" src="@/components/CommonPage/images/kakao02.png">
          <img class="kakaoch02" src="@/components/CommonPage/images/kakao03.png">
        </div>

      </div>
    </div>
  </div>
</template>

<script>

</script>

<style scoped>
.intro {
  width: 60%;
  margin-top: 30px;
  padding-left: 0px;
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 10px;
}

.t_word {
  font-weight: bold;
  padding-left: 10px;
}

.ch_desc{
  padding-top: 10px;
  width: 60%;
  font-size: 14px;
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 15px;
}
.caution{
  width:90%;
  padding:10px;
  border: 1px solid #c3b1b0;
  border-radius: 5px;
  font-size: 12px;
  color:#ab8482;
  font-weight: lighter;

}
.add_ch {
  width: 70%;
  padding-left: 0px;
  margin-top: 30px;
}

.kakaoch01{
  width:40%
}
.kakaoch02{
  width:50%
}

</style>