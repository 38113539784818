<template>
  <div id="notice_updates" class="updates">
    <div class="rollingbanner ">
      <div class="title">주요 업데이트 > </div>
      <div class="wrap">
        <ul>
          <li v-for="(item, index) in items" :key="index" :class="getClass(index)">
            <router-link to="/svcs/m"><span style="width:80px">{{ item.created_date }}</span><span class="up_str">|</span>{{ item.category }}<span class="up_str">|</span>{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import Upd from "@/data/noticedata.json";

export default {
  name: 'homeMain',
  data(){
    let upDateList = Upd.noticedata.sort((a, b) => {
      return b.content_id - a.content_id;
    }); // 내림차순
    return{
      loged:$store.state.user_log === null? 'notloged' : 'loged',
      items: upDateList,

    }
  },
  mounted() {
    if (document.getElementById('notice_updates')) {
      document.addEventListener('DOMContentLoaded', () => {
        var interval = window.setInterval(rollingCallback, 3000);
      });
    }
    function rollingCallback(){
      // 요소를 찾습니다.
      let current = document.querySelector('.rollingbanner .current');
      let next = document.querySelector('.rollingbanner .next');

      // 요소가 존재하는지 확인합니다.
      if (current && next) {
        // 요소가 존재할 때만 작업을 수행합니다.
        // .prev 클래스 삭제
        let prev = document.querySelector('.rollingbanner .prev');
        if (prev) {
          prev.classList.remove('prev');
        }

        // .current -> .prev
        current.classList.remove('current');
        current.classList.add('prev');

        // .next -> .current
        // 다음 목록 요소가 널인지 체크
        if (next.nextElementSibling == null) {
          document.querySelector('.rollingbanner ul li:first-child').classList.add('next');
        } else {
          // 목록 처음 요소를 다음 요소로 선택
          next.nextElementSibling.classList.add('next');
        }

        next.classList.remove('next');
        next.classList.add('current');
      }
    }
  },
  methods: {
    getClass(index) {
      if (index === 0) {
        return 'current';
      } else if (index === 1) {
        return 'next';
      } else if (index === this.items.length - 1) {
        return 'prev';
      } else {
        return '';
      }
    }
  }
}
</script>


<style>
/*롤링배너*/
.rollingbanner{
  position: relative;
  width: 570px;
  height: 32px;
  font-size: .875rem;
  letter-spacing: -1px;
  padding: 7px 15px;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border-radius: 16px;
  margin-top:30px;
}

.up_str{
  margin: 0px 10px;
  color: #a9a9a9
}

/* 타이틀 */
.rollingbanner > .title{
  font-weight: bold;
  color:black;
  float: left;
  padding-right: 10px;
}
/* 롤링 배너 */
.rollingbanner > .wrap{
  position: relative;
  width: auto;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}
.rolling ul{
  list-style: none;
}
.rollingbanner li{
  position: absolute;
  top: -36px;
  left: 0;
}
/* 이전, 현재, 다음 롤링 배너 표시 */
.rollingbanner li.prev{
  top: 36px;
  transition: top 0.5s ease;
}
.rollingbanner li.current{
  top: 0;
  transition: top 0.5s ease;
}
.rollingbanner li.next{
  top: -36px;
}
.rollingbanner a{
  display: block;
  display: -webkit-box;
  text-decoration: none;
  -webkit-line-clamp: 1;
  -webkit-box-orient:vertical;
  overflow: hidden;
  color: #000;
  padding-right:10px;
}

/*롤링배너 종료*/
</style>