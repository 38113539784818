<template>
  <div id="I_area">
    <div class="row col-12">
      <div class="title_block">
        <div class="title1">결산 자동화를 향한 도전</div>
        <div class="title2">AutoClosing</div>
        <div class="title3">
          AutoClosing은 회계의 기본인 신뢰성과 정확성을 기반으로<br>
          공시 및 결산 자동화 서비스를 제공하고 있습니다.<br><br>
          매분기 결산에 많은 시간을 소비하고 있는 회계 담당자분들을 위한 업무 파트너가 되고자 합니다.
        </div>
        <updates></updates>


        <div class="latest" >
          <div class="news_title"> TOPICS </div>
          <div class="news_column" v-for="(row, index) in comarray " :key="row.seqNm">
            <a class="noticelink" v-on:click="rowClick($event, row)" :index="row.seqNm">
              <td class="newsType"><span class="newsMark">▶</span>공지사항</td>
              <td class="newsSum">{{ row.title.length > 20 ? row.title.substring(0, 30) + '...' : row.title }}</td>
              <td class="newsDate">{{ row.createDate }}</td>
              <td class="more">more...</td>
            </a>
          </div>


        </div>

        <a class="kakaolink" href="/cmnt/kakaoch">
          <div class="kakaoch">
          <img class="kakaoimg" src="@/components/CommonPage/images/kakao04.png">
          <span>카카오톡 상담센터</span>
        </div>
        </a>
        <div class="mo_menu" v-if="loged==='notloged'">
          <router-link to="/login" style="text-decoration: none;"><p class="btn btn-primary SubmitBtn">회계자동화 서비스 로그인</p></router-link>
        </div>

      </div>
    </div>
    <br>
  </div>
  <div id="M_footer">
    Copyright 2023. PA Soft Co., Ltd. all rights reserved.
  </div>


</template>

<script>
import updates from "@/components/CommonPage/SubMenus/updates.vue"


export default {
  name: 'homeMain',
  components: {
   updates
  },
  data(){

    return{
      loged:$store.state.user_log === null? 'notloged' : 'loged',
      comarray: [],
      totalrows: 1,

    }
  },
  mounted(){
    this.getlist()
  },
  methods:{
    getlist() {
      const t =this
      const pages = 1
      const url = '/board/common/getArticleList'
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        "page": pages-1,
        "size": 3,// 페이지당 보여줄 갯수
        "type": 'NOTICE'
      }

      $api.getApiData(url,utoken,successStat,errorStat,getparam);
      function successStat (res) {
        const comdata = res.data

        t.comarray = comdata.content
        t.totalrows = comdata.totalElements
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },
    rowClick(e,row) {

      this.$router.push({
        name: 'N_Detail',
        params: {
          contentId: row.rowNumber
        },
        query: {
          seqNm: row.seqNm
        }
      });

    },
  }
}
</script>


<style scoped>


#I_area{
  color:white;
  width:100%;
  height:94vh;
  background-color: #0e2238;
  background: url("./images/main-resized03.jpg") no-repeat;
  background-position-y: 13%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: left;
  border-bottom: 1px solid lightgray;
}

#M_footer {
  margin-top: 35px;
  font-size: 12px;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #92a3bb;
  position: fixed;
  top: 90%;
  margin-left: auto;
  width: 100%;
}


.title_block{
  padding-left: 7%;
  margin-top: -3%;
}

.title1 {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: -15px;
  text-shadow: 2px 2px 2px black;
}
.title2 {
  padding-bottom: 30px;
  font-size: 100px;
  font-weight: 900;
  text-shadow: 2px 2px 2px black;
}

.title3 {
  font-size: 15px;
  font-weight: 200;
  margin-top: -15px;
  line-height:24px;
  color:#e3e3e3;
  text-shadow: 2px 2px 2px black;
}

.mo_menu { display:none;}

.mo_menu p {
  height: 58px;
  border: 1px solid #919191;
  border-radius: 100px;
  background-color: rgb(7 10 12 / 45%);
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 50px 40px 0px 40px;

}
.kakaoch{
  width: 180px;
  background-color: #ffde00;
  border-radius: 10px;
  margin-top:30px;
  color: black;
  font-weight: bold;
  text-align: center;
}

.kakaoimg {
  width:35px;
  margin:5px 7px 5px -6px;
}

.kakaolink{
  text-decoration: none;
}

.latest{
  width: 570px;
  background-color: #0000003b;
  margin: 20px 0px 20px 0px;
  border-radius: 13px;
  padding: 10px;


}

.news_title{
  border-bottom: 1px #b1b1b1b8 solid;
  color: #ffffffcc;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;

}
.news_column {
  margin-top: 5px;
  color: #dbdbdb;
  font-weight: bold;
  font-size: 12px;

}
.newsType{
  border-right: 1px #b1b1b1 solid;
  margin-right: 5px;
  padding-right: 5px;
  width:11%;
}
.newsSum {
padding-left: 10px;
}
.newsDate {
  font-size: 10px;
  margin-left: 5px;
  margin-right: 5px;
  border-left: 1px #b1b1b1 solid;
  border-right: 1px #b1b1b1 solid;
  padding-left: 5px;
  padding-right: 5px;
  width: 17%;
  text-align: center;
}
.more {
  font-size: 9px;
  text-align: right;
  width: 5%;
}
.newsMark{
  font-size: 9px;
  margin-right: 3px;
}
.noticelink{
  cursor: pointer;
  text-decoration: none;
}
.noticelink:hover {
  color: #9bc5f5;
}

</style>