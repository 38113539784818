<template>
  <div>

    <companyInfo/>

<!--    <div id="M_footer" style="color:#92a3bb">-->
<!--      Copyright 2023. PA Soft Inc. all rights reserved.-->
<!--    </div>-->
  </div>

</template>

<script>

import companyInfo from "@/components/CommonPage/SubMenus/Company/companyInfo.vue";

export default {
  name: 'Company',
  mounted: function () {

  },
  methods:{

  },
  components: {
    companyInfo
  }
}

</script>

<style scoped>
#M_footer{
  padding-top:0px;
  font-size: 12px;
  height:5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top:1px solid #92a3bb;
}
</style>

