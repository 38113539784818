<template>
  <div>
    <div id="IpBanner">
      <div  class="fcadescript mb-5">
        <div class="container content-space-2 content-space-lg-3">
          <div class="row">
            <div class="col-lg-12 mb-9 mb-lg-0">
              <h1 class="sol-001">FAQ<span class="sol-002"> 자주 묻는 질문</span></h1>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
<!--      FAQ 탭 추가-->
      <div>

      </div>


      <div class="infomain container content-space-2 content-space-lg-3">
        <div class="fcadescript2" >
          <div id="board_title" class="row">
            <div id="main_title">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
              </svg> 자주묻는 질문
            </div>
            <div id="sub_title">
              도움이 필요하신가요? 회계와 관련된 도움말 정보 입니다.
            </div>
            <b-tabs v-model="tabIndex" content-class="mt-3" style="margin-top: 30px; width:85%">
              <b-tab title="공통" :title-link-class="linkClass(0)" active>
                <div class="content content_mo" style="margin-top:10px; width: 95%; margin-left:20px; padding-bottom: 200px;">
                  <div class="accordion" style="margin-top:40px;">
                    <div class="accordion-item border-top border-300">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                          결산 회계처리 중에 유난히 시간이 많이 걸리는 프로세스가 있습니다.
                          혹시 AutoClosing에 모듈 개발을 요청드려도 될까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapseOne" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body pt-2">
                          : 물론입니다. AutoClosing은 회계업계 종사자 분들의 보다 빠르고 정확한 업무처리를 위하여 설립된 회사입니다.
                          언제든지 제안을 주시면 개발자와 현직 회계사와의 논의를 통해 서비스가 가능한 부분이라고 판단되면 개발을 진행하여 홈페이지에 공통으로 제시된 모듈로 서비스를 제공해드릴 예정입니다. 또한 요청주신 모듈이 개발되는 경우에는 요청주신 분에게 소정의 보답(상품권)도 드리고 있으니 많은 요청 부탁드립니다.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseOne">
                          저희 회사의 프로세스는 회사에 국한되고 복잡한지라 공통된 모듈로는 서비스 받기 어려울 것 같습니다.
                          <br>이에 대한 Customizing 개발 서비스를 제공받을 수 있을까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapseTwo" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 물론입니다. 관련하여 개발을 요청하실 의사가 있는 경우에는 고객센터를 통해 연락주시면 내부 검토를 통해 유선 또는 메일로 답변을 드릴 예정입니다. AutoClosing은 현직 회계사의 자문을 통해 개발이 이루어지기 때문에 기능 수정에 대한 피드백이 타사보다 월등히 빠르고 정확하게 진행될 수 있습니다.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseOne">
                        현재는 무료서비스로 이용이 가능합니다만, 유료서비스가 예정되어 있을까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapseThree" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : AutoClosing은 회계업계 종사자 분들을 위하여 설립된 회사입니다. 서버 비용을 자체적으로 충당 가능한 수준까지는 최대한 무료로 서비스를 제공해드릴 예정입니다.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseOne">
                        산출물에 나타난 회계처리에 오류가 있는 것으로 판단됩니다. 이에 대하여 문의를 드려도 될까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapseFour" aria-labelledby="headingFour" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 얼마든지 가능합니다. 고객센터를 통해 문의를 부탁드립니다. 저희 모듈은 공통적으로 생성되는 모듈인지라 각 회사의 세밀한 점까지는 맞추기 어려운 부분이 있을 가능성이 있습니다. 죄송하지만 이러한 점도 감안 부탁드립니다.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFIve"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseOne">
                        아직 제공하는 모듈 서비스가 많지 않은데 추가될 모듈 서비스에는 어떠한 것이 있을까요?<br>그리고 향후 AutoClosing에서 제공될 회계 서비스의 범위가 어느 정도일까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapseFive" aria-labelledby="headingFIve" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 질의하시는 분이 생각하실 수 있는 어떠한 범위의 회계 자동화 서비스도 제공 가능합니다. ERP에서 제공하지 못하거나 구축 시 과도한 비용을 요구하는 프로세스를 보다 편하고 빠르게 사용하실 수 있도록 하는 것이 PA Soft의 설립 목표입니다.
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </b-tab>

              <b-tab  title="리스회계처리" :title-link-class="linkClass(1)">
                <div class="content content_mo" style="margin-top:10px; width: 95%; margin-left:20px; padding-bottom: 200px;">
                  <div class="accordion" style="margin-top:40px;">
                    <div class="accordion-item border-top border-300">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2-1" aria-expanded="false" aria-controls="collapseOne">
                          자동화 회계처리가 회사의 리스 회계처리와 조금 상이한 부분이 있습니다. 이러한 차이에 대하여 로직에 수정 반영해주실 수 있을까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse2-1" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body pt-2">
                          : 물론입니다. AutoClosing의 취지는 회계종사자 분들의 업무를 돕고자 함이기 때문에 고객센터를 통해 요청을 받고 있습니다.
                          <br>다만, 요청이 많거나 복잡한 경우일 경우에는 다소 시간이 걸릴 수 있음을 양해 부탁드립니다. 혹시 늦어지게 되는 경우라도 즉시 답변을 드리도록 하겠습니다.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2-2" aria-expanded="false" aria-controls="collapseOne">
                          리스 계산 내용에 대하여 매 기간별로 관리를 하고 싶습니다. 가능할까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse2-2" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 기간별 관리는 일회성의 산식 제공보다 다소 고차원적인 시스템이 필요합니다. 서버 및 DB 관리를 포함한 비용적인 측면이 많아 죄송하지만 향후 유료서비스 시에 대응이 가능하지 않을까 싶습니다.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2-3" aria-expanded="false" aria-controls="collapseOne">
                          리스 모듈에 추가 기능을 요청할 수 있을까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse2-3" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 물론입니다. 고객센터를 통해 요청부탁드립니다.
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </b-tab>

              <b-tab title="DSD Footing" :title-link-class="linkClass(2)">
                <div class="content content_mo" style="margin-top:10px; width: 95%; margin-left:20px; padding-bottom: 200px;">
                  <div class="accordion" style="margin-top:40px;">
                    <div class="accordion-item border-top border-300">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3-1" aria-expanded="false" aria-controls="collapseOne">
                          DSD 정보는 공시 전에는 정보보안 차원에서 유출이 되지 않아야 하는 자료입니다. 자료를 처리하는 과정에서 내용이 유출될 수 있지는 않을까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse3-1" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div class="accordion-body pt-2">
                          : AutoClosing은 DSD Footing과 같이 정보보호가 필요한 내용에 대하여 보안 정책 및 암호화 과정이 적용되어 모듈이 구동 되므로, 이용자 외에는 접근이 불가능합니다. 해당 과정은 외부뿐만 아니라 내부에도 동일하게 적용 되므로 사용자의 민감정보는 유출되지 않도록 설계되어 있습니다.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3-2" aria-expanded="false" aria-controls="collapseOne">
                          DSD 검토 뿐만 아니라 검증이 완료된 상태로 작성이 된다면 공시 작업이 더 편할 것 같은데 개발 계획이 없으실까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse3-2" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 현재 개발중인 모듈로는 DSD 및 XBRL 작성기 등이 있습니다. 이를 통해 보다 공시자료를 수월하게 만드실 수 있도록 서비스를 제공할 예정입니다.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3-3" aria-expanded="false" aria-controls="collapseOne">
                          DSD 검토 요청을 진행하였습니다만, 회사 양식에 적용하기에는 검토에 일부 오류가 발생합니다. 이런 오류사항을 수정 반영하여 온전히 검토가 가능하도록 모듈 수정이 가능할까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse3-3" aria-labelledby="headingThree" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 물론입니다. 다만, DSD Footing 모듈은 내부정보보호를 위하여 입력 및 출력하신 정보에 대하여 회사 내부적으로 누구도 접근할 수 없도록 하고 있기 때문에 산출물의 확인이 어렵습니다. 그러므로 고객센터를 통해 오류수정을 요청하시기 위해서는 필히 공시가 완료된 전년도 자료로 요청해주시기 바랍니다. 그 범위를 벗어나는 수정은 죄송하지만 저희도 대응하지 않을 예정입니다.
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingFour">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3-4" aria-expanded="false" aria-controls="collapseOne">
                          DSD 검토 관련하여 추가 기능을 요청하고 싶은데 가능할까요?</button></h2>
                      <div class="accordion-collapse collapse" id="collapse3-4" aria-labelledby="headingFour" data-bs-parent="#accordionExample" style="">
                        <div class="accordion-body pt-2">
                          : 물론입니다. 고객센터를 통해 구체적으로 필요하다고 판단하시는 대사 내용을 기재 부탁드립니다. <br>(예시 : 어떠한 계정의 어떠한 항목과 재무제표간의 대사)
                        </div>
                      </div>
                    </div>

                  </div>


                </div>
              </b-tab>


            </b-tabs>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'faqPage',
  data() {
    return {
      tabIndex: 0
    }
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return []
      } else {
        return ['text-black-50']
      }
    }
  }
}
</script>

<style scoped>
.accordion{
  --bs-accordion-active-color: #004fc5;
}
.accordion-item {
  border-left:none !important;
  border-right:none !important;
  font-family: "Pretendard-Regular" !important;
  color: #606773;
  line-height: 26px;
  font-size: 15px;
  font-weight: lighter;

}


.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: transparent;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  font-weight: bold;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: none !important;
}


.accordion-header {
  font-family: "Pretendard-Regular";
}

.accordion-body {
  padding-bottom: 60px;
}

.fcadescript{height:200px;
  background: linear-gradient(to top, rgb(0 0 0 / 10%), rgba(255, 255, 255, 0));

}



.fcadescript2{
  padding-top:75px ; padding-bottom:100px !important; }

#IpBanner {
  background-image: url(@/components/CommonPage/images/companyimg.jpg);
  background-repeat: no-repeat; /* 배경이미지X */
  background-size: cover; /* 요소를 비율에 맞게 커버 */
  background-position: center; /* 이미지를 요소의 정가운데로 처리 */
//background-attachment: fixed; /* 스크롤바 움직일때 이미지가 따라다님 */
}

.sol-001{padding:64px 50px 15px 50px;
  color:white;
  font-size:39px;
  font-weight:700;
  font-family: Pretendard-Regular;
}

.sol-002{color:white;
  padding-left:20px;
  font-family: Pretendard-Regular;
  font-size:25px;}


.infomain{
  padding-left: 13%;
  font-size: 16px;
  line-height: 29px;
}



</style>