<template>
  <div id="main_body" >
    <!--    네비게이션-->
    <nav id="mainavi" class="navbar navbar-expand-lg bg-body-tertiary sticky-top" >
      <div class="container-fluid">
        <img :src="logo" alt="Logo" width="153" height="" class="d-inline-block align-text-top" style="margin-left: 11px;
                      margin-right: 49px;">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <!--메뉴리스트-->

      </div>
    </nav>
    <!--메인프레임 종료 하단 라우터뷰 -->
    <div id="UserContents" style="padding-top: 0px;">
      <div>
        <div id="whole" class="container d-lg-flex align-items-lg-center content-space-t-3 content-space-lg-0 min-vh-lg-100" >
          <div class="p404_window">
            <div class="p404_title">

              <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-wrench-adjustable-circle" viewBox="0 0 16 16">
                <path d="M12.496 8a4.491 4.491 0 0 1-1.703 3.526L9.497 8.5l2.959-1.11c.027.2.04.403.04.61Z"/>
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-1 0a7 7 0 1 0-13.202 3.249l1.988-1.657a4.5 4.5 0 0 1 7.537-4.623L7.497 6.5l1 2.5 1.333 3.11c-.56.251-1.18.39-1.833.39a4.49 4.49 0 0 1-1.592-.29L4.747 14.2A7 7 0 0 0 15 8m-8.295.139a.25.25 0 0 0-.288-.376l-1.5.5.159.474.808-.27-.595.894a.25.25 0 0 0 .287.376l.808-.27-.595.894a.25.25 0 0 0 .287.376l1.5-.5-.159-.474-.808.27.596-.894a.25.25 0 0 0-.288-.376l-.808.27.596-.894Z"/>
              </svg>
            </div>
            <div class="p404_desc">
              서비스에 불편을 드려 죄송합니다.<br>
              현재 서비스의 일시 점검 중에 있습니다. 빠르게 정상화 하도록 하겠습니다.
            </div>
          </div>
        </div>
        <div id="M_footer" >
          Copyright 2023. PA Soft Co., Ltd. all rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>



<script>

export default {
  name:'UserMain',
  data () {
    return {
      logo: require('./images/AutoClosing02.png'),

    }
  },
  components:{},

  mounted() {


  },

  methods: {


  }
}

</script>

<style>

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

#UserContents {
  font-family: Pretendard-Regular;
}

#mainavi {
  padding:7px 2% 7px 35px;
  background-color: white;
  border-bottom: lightgray 1px solid;
  height: 6vh;
}

.nav-item a{
  padding: 7.5px 30px !important;
  font-family: 'Pretendard-Regular';
  font-size:17px;
}

.nav-item a:hover{
  color: #304769 !important;
  background-color: #172c4717;
  border-radius: 7px;
  font-weight: 800;
}

#whole {
  background: url("../CommonPage/images/login-resized03.jpg") no-repeat;
  background-position-y: 13%;
  background-size: cover;
  max-width: 100% !important;
  height:94vh;
  margin-top: 0px;
  margin-left: 0;
  margin-right: 0;
//background-color:#526374;
  border-bottom: 1px solid #002d5c29;
}

#M_footer {

  font-size: 12px;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #92a3bb;
  position: fixed;
  top: 90%;
  margin-left: auto;
  width: 100%;
}

.p404_window{
  margin:auto;
  text-align: center;
}

.p404_title{
  font-size: 60px;
  color:white;
  margin-bottom: 10px;
}
.p404_desc{
  font-size: 13px;
  color:white;
  margin-bottom: 40px;
}

.p404_btn{

  height: 35px !important;
  padding: 0px 23px !important;
  color: rgb(14, 34, 56) !important;
  border: 1px white !important;
  border-radius: 20px !important;

}

.p404_btn a {
  text-decoration: none;
}


/*--모바일 CSS==*/

@import '../../pastyle.css';


</style>