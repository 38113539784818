import {createStore} from "vuex";

const store = createStore({
    state() {
        return{
            basedomain:'https://autoclosing.ai/api',
            // basedomain:'http://localhost',
            token:localStorage.getItem("access-token"),
            t_verify: localStorage.getItem("tokenAvailable"),
            user_log:localStorage.getItem("user-name"),
            userId: localStorage.getItem("accseqnm"),
            userRole: localStorage.getItem("accountRole"),
            webStatus: true

        }
    },

    mutations: {

        setUserToken: (state, payload) => {
            // state.token = payload;
            localStorage.setItem("access-token", payload.data.data.token);
            localStorage.setItem("user-name", payload.data.data.userName);
            localStorage.setItem("tokenAvailable", payload.data.data.tokenExpire);
            localStorage.setItem("accseqnm", payload.data.data.accountSeqNm);
            localStorage.setItem("mobileAuth", payload.data.data.mobileAuth);
            localStorage.setItem("accountRole", payload.data.data.accountRole);
            window.location.href = '/'

        },


    },
    actions:{

        startlogin: ({commit},payload) => {
            commit("setToken",payload);
        },
        Userlogin: ({commit},payload) => {
            commit("setUserToken",payload);
        },


    }
});

export default store;