<template>
  <div style="margin-top: 0px;">
    <div id="IpBanner">
      <div  class="fcadescript mb-5">
        <div class="container content-space-2 content-space-lg-3">
          <div class="row">
            <div class="col-lg-12 mb-9 mb-lg-0">
              <h1 class="sol-001">CONTACT US<span class="sol-002"> 고객센터</span></h1>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="contactus" class="bg-light mx-lg-10 mailform">
      <div class="container content-space-2 content-space-lg-3">
        <form ref="form" @submit.prevent="sendEmail">
          <div class="row">
            <div class="col-sm-6 mailcol">
              <h4 class="mailHeader mb-3">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-fill" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"/>
                </svg> 고객 센터 </h4>
              <p class="mailSub mb-3">회계와 관련된 궁금증이나 회계 자동화 기능의 사용문의 및 개선/건의사항 등
                <br>고객 여려분의 다양한 의견을 보내주세요. </p>
              <label class="form-label">Title</label>
              <input id="namearea" class="form-control sendMailTitle" name="qnaTitle" placeholder="제목을 입력해 주세요">
              <label class="form-label">Message</label>
              <textarea id="textarea" v-model="context" class="form-control sendMailContext" name="message" placeholder="문의 내용을 입력해 주세요."></textarea>
              <div style="margin-bottom: 30px; text-align: end">
                ({{conLength}}/ 1000)
              </div>
              <div class="col-sm-12 mailcol" style="text-align: center;">
                <input class="btn detail_btn btn-primary" type="submit" value="보내기">
              </div>

            </div>


          </div>
        </form>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'GuestMailForm',
  data() {

    return {
      context:'',
      conLength:0

    }
  },
  watch:{
    context: function() {
      this.conLength = $('#textarea').val().length
      if(this.conLength ===1000){
        alert("게시물 내용은 1000자를 넘을 수 없습니다.")
      }
    }
  },
  methods: {
    sendEmail() {
      let a = localStorage.getItem("access-token")
      if (a != null) {
        if($('#namearea').val() != null && $('#textarea').val().length > 10 ){
          $axios.post($store.state.basedomain + '/faq/send', null, {
            headers: {
              authorization: 'bearer ' + $store.state.token
            },
            params: {
              "title": $('#namearea').val(),
              "body": $('#textarea').val()
            }
          })

              .then(function () {
                alert('문의를 송부 하였습니다. 빠르게 답변 드리겠습니다.')
                window.location.href = '/gmail'
              })
              .catch(function () {
                alert('시스템 오류입니다. 문의내용을 송부하지 못했습니다.')
                window.location.href = '/gmail'
              });
        }
        else {
          alert("제목(필수) 입력이며 본문은 10자이상 입력바랍니다.")
        }
      }
      else {
        alert("로그인 정보가 없습니다. 로그인 해 주십시오.");
      }
    }
  }
}

</script>

<style scoped>

#IpBanner {
  background-image: url(@/components/CommonPage/images/companyimg.jpg);
  background-repeat: no-repeat; /* 배경이미지X */
  background-size: cover; /* 요소를 비율에 맞게 커버 */
  background-position: center; /* 이미지를 요소의 정가운데로 처리 */
//background-attachment: fixed; /* 스크롤바 움직일때 이미지가 따라다님 */
}

.fcadescript{height:200px;
  background: linear-gradient(to top, rgb(0 0 0 / 10%), rgba(255, 255, 255, 0));

}


.sol-001{padding:64px 50px 15px 50px;
  color:white;
  font-size:39px;
  font-weight:700;
  font-family: Pretendard-Regular;
}

.sol-002{color:white;
  padding-left:20px;
  font-family: Pretendard-Regular;
  font-size:25px;}

.mailclick h2 { font-size:35px !important; font-weight: 500;}

.mailHeader {
  font-size: 22px;
  font-weight: bold;
}

.mailSub {
  font-size: 13px;
  padding-bottom: 30px;
}


.mailform {
  background: white !important;
  margin:0px;
  color:rgb(0, 0, 0);
  padding: 50px 100px 100px 100px;
}

.mailcol {    margin: 0px auto;}


#namearea {
  width:300px;
  margin-bottom:30px;
}

#contact_area {
  width:400px;
  margin-bottom:30px;
}

#title_area {
  margin-bottom:30px;
}

#textarea {
  height:150px;
  margin-bottom:3px;
  resize:none;
  height:250px;
}

.detail_btn {
  margin: 0px 10px;
  height: 35px;
  width:100px;
  padding: 1px 10px;
  background-color: #00264a;
  border: 0px;
}
</style>