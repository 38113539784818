<template>
  <div>
    <div id="menu_List" style="margin-top: 61px;">
      <p class="HeadLine">커뮤니티</p>
      <ul class="ul_style">
        <li class="menu_link">

          <a to="" class="r_link" href="#BoardCollapse" data-bs-toggle="collapsed" role="button" aria-expanded="true" aria-controls="BoardCollapse" style="color: #cde3fd;" disabled>
            <svg  style="margin-top: -4px;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-text" viewBox="0 0 16 16">
              <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
              <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
            </svg>
            <span>고객소통공간</span>
          </a>
          <div class="collapsed" id="BoardCollapse" style="">
            <ul class="sub_items">
              <li class="nav-item">
                <router-link to="/cmnt/kakaoch" >
                  상담센터
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/cmnt/nlist/1" >
                  공지사항
                </router-link>
              </li>
<!--              <li class="nav-item">-->
<!--                <router-link to="/cmnt/list/1" >-->
<!--                  자유게시판-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link to="/cmnt/qnalist/1" >-->
<!--                  질문게시판-->
<!--                </router-link>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <router-link to="/cmnt/faq" >-->
<!--                  자주묻는 질문-->
<!--                </router-link>-->
<!--              </li>-->
            </ul>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
export default {

  mounted() {

  },
  methods: {

  }
}
</script>


<style scoped>

.HeadLine{
  margin-left: -11px;
  font-size: 17px;
  font-family: pretendard-regular;
  color: white;
  font-weight: 400;
}

.r_link {
  font-family: pretendard-regular;
  color: #b6c8dd !important;
  font-weight: 400 !important;
  font-size: 14px;
  margin-bottom: -14px;
}

.ul_style {
  margin-top: 0;
  box-sizing: border-box;
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.90625rem;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  list-style: none;
  padding-left: 40px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  flex-direction: column;
  margin-left: calc(var(--bs-navbar-padding-x)*-2.5);
  margin-right: calc(var(--bs-navbar-padding-x)*-1);

}

.ul_style li {
  position: relative;
  box-sizing: border-box;
  display: list-item;
  text-align: -webkit-match-parent;
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.90625rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  list-style: none;
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;

}

.ul_style li a {
  align-items: center;
  border-bottom-right-radius: 0.875rem;
  border-top-right-radius: 0.875rem;
  display: block;
//color: var(--bs-nav-link-color);
//font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  text-decoration: none;
  margin-right: 1.875rem;
  /*padding-left: var(--bs-navbar-nav-link-padding-x);*/
  padding-left: 20px;
  font-size: 14px;
  line-height: 30px;
  color:grey;

}

.ul_style li a:hover {color:#7ea2cb !important; }

.sub_items {

  padding-left: 0px;
}

.sub_items a {
  padding: 3px 0px 3px 60px !important;
}

svg {
  margin-right: 0.625rem;
}

.router-link-active {
  color:white !important;
//background-color: #434a52;
  transition: 0.1s;
}

</style>


