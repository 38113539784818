<template>
  <div class="addForm" >

    <form @submit="submitUser" style="width:800px;" autocomplete="off">
      <!--     // 상단 문구 //-->
      <div class="row mb-3">
        <div class="col-sm-12" style="margin-top: -35px;">
         <span style="color:#0e2238; font-weight: bold; font-size:20px; border-bottom: #0e2238 1px solid;">
           회원정보 페이지
         </span><br><br>
          <span>회원정보 페이지 입니다.</span><br>
          변경을 원하시는 내용을 수정하여 저장해 주십시오.<br>
          <br><span class="must">*</span> 항목은 수정이 불가능합니다.
        </div>
      </div>
      <hr><hr>
      <!--     //이메일 입력//-->
      <div class="row mb-3">
        <label for="mail" class="col-sm-3 col-form-label">이메일<span class="must">*</span></label>
        <div class="col-sm-4">
          <input v-model="usermail" type="email" class="form-control" id="mail"  disabled>
        </div>


      </div>
      <!--     //비밀번호 입력//-->
      <div class="row mb-3">
        <label for="pass" class="col-sm-3 col-form-label">비밀번호</label>
        <div class="col-sm-4">
          <input v-model="chkpass" type="password" class="form-control" id="pass" >
        </div>
        <div class="col" style="font-size: 13px;">8자리이상 영문자, 숫자, 특수문자</div>
      </div>
      <!--     //비밀번호 재입력//-->
      <div class="row mb-3">
        <label for="repass" class="col-sm-3 col-form-label">비밀번호[재입력]</label>
        <div class="col-sm-4">
          <input v-model="repass" type="password" class="form-control" id="repass" placeholder="">
        </div>
        <div class="col" style="font-size: 13px;" v-if="passShow===1"></div>
        <div class="col" style="font-size: 13px; color:darkred;" v-if="passShow===2">비밀번호가 일치하지 않습니다.</div>
        <div class="col" style="font-size: 13px; color:green;" v-if="passShow===3">비밀번호가 일치합니다.</div>
      </div>
      <hr>
      <!--     //이름 입력//-->
      <div class="row mb-3">
        <label for="userName" class="col-sm-3 col-form-label">이 름<span class="must">*</span></label>
        <div class="col-sm-3">
          <input v-model="username" type="text" class="form-control" id="userName" disabled>
        </div>

      </div>

      <!--     //휴대전화 입력//-->
      <div class="row mb-3">
        <label for="phone" class="col-sm-3 col-form-label">휴대전화<span class="must">*</span></label>
        <div class="col-sm-3">
          <input v-model="userphone" type="tel" class="form-control" id="phone"  disabled>
        </div>
        <div class="col-sm-2 " style="" v-if="mobileAuth===false"><button type="button" class="varify" @click="varifySelf" >본인인증</button></div>
        <div class="col-sm-2 " v-if="mobileAuth===true"><button type="button" class="varify_disable"  disabled >인증완료</button></div>
        <div class="notice_Auth" v-if="mobileAuth===false"> ※ 서비스 이용을 위해서는 본인인증이 필요합니다. <br>[인증완료] 상태가 아닌 경우 본인인증을 진행해 주세요.</div>
      </div>
      <hr>
      <!--     //닉네임 입력//-->
      <div class="row mb-3">
        <label for="nickName" class="col-sm-3 col-form-label">닉네임</label>
        <div class="col-sm-3">
          <input v-model="userNick" type="text" class="form-control" id="nickName" disabled>
        </div>
        <div class="col-sm-2 startNick" style="display:none;"><button type="button" class="varify" @click="varifyNick" >증복확인</button></div>
        <div class="col-sm-2 resetNick" style=""><button type="button" class="varify" @click="resetNick" >변경하기</button></div>

      </div>

      <!--     //회사명 입력//-->
      <div class="row mb-3">
        <label for="userComp" class="col-sm-3 col-form-label">회사명</label>
        <div class="col-sm-4">
          <input v-model="usercompany" type="text" class="form-control" id="userComp" >
        </div>
      </div>
      <!--     //직책 입력//-->
      <div class="row mb-3">
        <label for="userJob" class="col-sm-3 col-form-label">직책</label>
        <div class="col-sm-3">
          <input v-model="userposition" type="text" class="form-control" id="userJob">
        </div>
      </div>


      <!--     //수정버튼//-->
      <div style="margin-top:50px; margin-bottom: 200px;">
        <button type="submit" id="confirm_btn" class="btn btn-primary varify" @click="reviseUser">수정하기</button>
      </div>

    </form>
  </div>


  <!--    본인인증 완료 모달-->
  <button type="button" class="varify Run_A_Modal" data-bs-toggle="modal" data-bs-target="#user_Varidation_Comp" style="display: none;">인증완료</button>

  <div class="modal fade" id="user_Varidation_Comp"  data-bs-keyboard="false" tabindex="-1" aria-labelledby="Point_viewlabel" aria-hidden="true">
    <div id="Point_Window" class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content" style="width: 500px; margin: auto;">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">Authorization</h1>
          <button type="button" class="btn-close E_modalClose" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" style="padding-top: 0px;">
          <form autocomplete="off">
            <h4>사용자 정보 인증</h4>
            <div class="varid_Title">
              <span>- 사용자 본인 인증이 완료 되었습니다. </span><br>
            </div>
            <div class="input_Valid">
              <div class="col-sm-12" style="align-items: center; display: flex; justify-content: center">
                <div id="re_confirm1" class="original col-sm-2" style="background-color: #0e2238; margin-right: 10px;" @click="updateAuth">확   인</div>
              </div>
            </div>

          </form>

        </div>


      </div>
    </div>
  </div>



</template>


<script>

export default {
  data() {
    return {
      chkpass:"",
      repass:"",
      passShow:1,
      nickPass: true,
      // 타이머 구현
      Timer: null,
      TimeCounter: 300,
      TimerStr: "05:00",
      //유저정보
      usermail:'',
      username:'',
      userNick:'',
      nickCompare:'',
      usercompany:'',
      userposition:'',
      userphone:'',
      infoCode:'',
      mobileAuth:null,
      childWindow:null

    }
  },
  watch:{

    repass: function () {
      this.varifyPassWord()
    }

  },
  mounted() {
    this.getUserinfo()

    },

  methods: {
    insert(){
      localStorage.setItem("mobileAuth", "true");
    },

    getUserinfo() {
      const t =this
      const url = '/account/info'
      const utoken =$store.state.token != null ? true : false;

      $api.getApiData(url,utoken,successStat,errorStat);
      function successStat (res) {

        t.usermail = res.data.data.emailAddr;
        t.username = res.data.data.ownerName;
        t.userNick = res.data.data.nickName;
        t.nickCompare = res.data.data.nickName;
        t.usercompany = res.data.data.company;
        t.userposition = res.data.data.position;
        t.userphone = res.data.data.phoneNumber;
        t.mobileAuth = res.data.data.mobileAuth;
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }

    },


    //비밀번호 재입력 체크//
    varifyPassWord() {
      if(this.repass === "") {
        // console.log("비밀번호를 다시 입력해 주세요.")
        this.passShow = 1;
      }
      else if(this.repass === this.chkpass) {
        // console.log("비밀번호가 일치합니다.")
        this.passShow = 3;
      }
      else{
        // console.log("비밀번호가 일치하지 않습니다.");
        this.passShow = 2;}
    },

    //인증 창 닫기//
    closeWindow(){
      const targetWindow = window.open('', 'startAuth');
      if (targetWindow && !targetWindow.closed) {
        targetWindow.close();
      }
    },
    //본인인증//
    varifySelf() {
      this.childWindow =
      window.open('/api/nicecheck/getAuth/CHECK_INFO', 'startAuth',
      'width=500, height=550, top=100, left=100, fullscreen=no, menubar=no,' +
      ' status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');

      window.addEventListener('message', this.Message.bind(this));
    },

    //본인인증에서 받은 메세지를 부모윈도우에서 출력//
    Message(event) {
      if (event.data.type === 'authResult') {
        // 메시지 수신 후 창닫고 인증코드 등록하기

        const t =this
        if (event.data.result) {
          // 미인증 사용자
          t.infoCode = event.data.infoCode
          $('.Run_A_Modal').click()
          t.closeWindow()
        }
      }
    },

    updateAuth(){

      const url = '/account/updateMobileAuth'
      const utoken =$store.state.token != null ? true : false;
      const getdata ={
        "infoCode": this.infoCode
      }

      $api.postApiData (url,utoken,successStat,errorStat,getdata);
      function successStat (res) {
        localStorage.setItem("mobileAuth", "true");
        window.location.href = '/accpage/myinfo'
      }
      function errorStat (error) {
        alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
      }
    },

    //닉네임 중복체크//
    varifyNick() {

      const t=this
      const url = '/account/nickNameValid'
      const nickvd = $('#nickName').val();
      const utoken =$store.state.token != null ? true : false;
      const getparam = {
        nickName:nickvd
      }
      if(nickvd === '' || nickvd === null){ alert("닉네임을 입력해 주세요.")}
      else {
        $api.getApiData(url, utoken, successStat, errorStat, getparam);

        function successStat(res) {
          if (res.status === 200) {
            alert("사용가능한 닉네임입니다")
            t.nickPass = true
            $('#nickName').attr('disabled', 'true');
            $('.startNick').css('display', 'none');
            $('.resetNick').css('display', 'block');
          }
        }

        function errorStat(error) {
          alert("이미 사용중인 닉네임입니다.")
        }
      }

    },

    resetNick() {
      this.nickPass = false
      $('.startNick').css('display','block');
      $('.resetNick').css('display','none');
      $('#nickName').removeAttr('disabled');
    },

    // 휴대전화 인증 시작 모달 실행//
    varifyPhone() {
      // console.log("휴대전화 인증 요청 보내기")
      this.runTimer()
      $('.Run_P_Modal').click()

    },
    //휴대전화 인증 시작//
    confirmPhone() {
      if($('#phone_valid').val()=='9tSs6fxE84cJklR') {
        $('.P_modalClose').click();
        // console.log("모달 닫기")
        $('#phone').attr('disabled','true');
        // console.log("휴대전화 비활성화")
        $('.startPhone').css('display','none');
        $('.resetPhone').css('display','block');
      }
      else {
        // console.log('인증문자가 맞지 않습니다');
        alert('인증문자가 맞지 않습니다')
      }

    },
    //휴대전화 재인증//
    resetPhone() {
      $('.startPhone').css('display','block');
      $('.resetPhone').css('display','none');
      $('#phone').removeAttr('disabled');
    },

    // 타이머 구현
    runTimer: function() {
      if(this.Timer != null){
        this.timerStop(this.Timer);
        this.Timer = null;
      }
      this.Timer = this.timerStart();
    },

    timerStart: function() {
      // 1초에 한번씩 start 호출
      this.TimeCounter = 300;
      var interval = setInterval(() => {
        this.TimeCounter--; //1초씩 감소
        this.TimerStr = this.prettyTime();
        if (this.TimeCounter <= 0) this.timerStop(interval);
      }, 1000);
      return interval;
    },
    timerStop: function(Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
    },
    prettyTime: function() {
      // 시간 형식으로 변환 리턴
      let time = this.TimeCounter / 60;
      let minutes = parseInt(time);
      let secondes = Math.round((time - minutes) * 60);
      return (
          minutes.toString().padStart(2, "0") +
          ":" +
          secondes.toString().padStart(2, "0")
      );
    },




    reviseUser(event) {
      event.preventDefault();
        const validatePass = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/
        if (!validatePass.test(this.chkpass) || !this.chkpass ) {
          alert("비밀번호 형식이 맞지 않습니다. 8자리 이상의 영문,숫자,문자 조합으로 입력해 주세요.")
          return
        }
        else{
          if( this.repass === this.chkpass ) {
            if ($("#nickName").val() === "" || this.nickPass === false) {
              alert("닉네임 인증을 확인해 주십시오")
            }
            else{
              if($('#phone').val() != ""){

                const url = '/account/update'
                const utoken =$store.state.token != null ? true : false;
                const getdata ={
                  "password": $('#pass').val(),
                  "company": $('#userComp').val(),
                  "position": $('#userJob').val(),
                  "phone": $('#phone').val(),
                  "nickName": $('#nickName').val()
                }

                $api.postApiData (url,utoken,successStat,errorStat,getdata);
                function successStat (res) {
                  alert("회원정보 수정이 완료 되었습니다.");
                  window.location.href = '/accpage/myinfo'
                }
                function errorStat (error) {
                  alert("오류가 발생하였습니다. 페이지를 새로고침 해주세요.")
                }


              }
              else{
                alert("핸드폰 번호를 입력해 주세요.")
              }
            }
          }
          else{
            alert("비밀번호를 재확인 해 주십시오")
          }

        }


    }
  },
  beforeDestroy() {
    // 컴포넌트가 파괴될 때 메시지 수신 리스너 제거
    window.removeEventListener('message', this.handleMessage.bind(this));
  }
}
</script>

<style scoped>
.notice_Auth{
  margin:10px 5px 10px 210px;
  width:350px;
  padding: 5px 15px;
  font-size: 12px;
  color: #bd4e4e;
  border:1px solid #92a3bb;
  border-radius: 5px;
}

.modal {
  --bs-modal-width:800px;
  --bs-modal-border-radius: 4px;
}
.modal-header {
  height: 40px;
}
.modal-header h1 { margin-top: 0px;}
.modal-body h4 {
  font-size: 17px;
  font-weight: bold;
}
.varid_Title{
  margin-bottom: 21px;
  margin-top: 15px;
  font-size: 13px;
}
#email_valid {
  border: 1px solid #7e7e7e;
}

.addForm {
  width: 100%;
  margin: 80px auto;
  /*margin-left: 40px;*/
  align-items: center;
  height: auto;
  justify-content: center;
  display: flex;


}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #10263f;
  background-color: transparent;
  background-clip: padding-box;
  border: none;
  border-bottom : 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /*margin-bottom:30px;*/
}

.form-control:focus {
  color: #212529;
  background-color: rgb(4 37 64 / 8%);
  border-bottom : 1px solid #ced4da;
  outline: 0;
  box-shadow: none;
}

.form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1 !important;
  color: #a7a7a7 !important;
}

.must {
  color: red;
}

.varify {
  width:100px;
  height:27px;
  border:none;
  color:white;
  background-color: #10263f;
  font-size:13px;
  margin-top: 3px;
}

.varify_disable {
  width:100px;
  height:27px;
  border:none;
  color:white;
  background-color: #adadad;
  font-size:13px;
  margin-top: 3px;
}


.original {
  width: 100px;
  height: 25px;
  border: none;
  color: white;
  background-color: #959595;
  font-size: 12px;
  text-align: center;
  line-height: 25px;
  float:right;
  cursor:pointer;
}


#confirm_btn {
  width:160px;
  height:35px;
  font-size: 18px;
  margin-left: 50%;
  position: relative;
  left:-80px;
  margin-top:15px;
  border-radius: 0px;

}
</style>