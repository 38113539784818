export function getApiData (url,utoken,successStat,errorStat,getparam) {
    var header = {}
    var param = {}
    if(utoken){
        header.authorization = 'bearer ' + $store.state.token
    }
    if(getparam){
        param = getparam
    }
    $axios.get( $store.state.basedomain+url
    ,{
            headers : header,
            params: param
        })
        .then( function (res) {
            successStat(res);
        })

        .catch( function(error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized 응답 처리
                alert('로그인 정보가 만료되었습니다. 다시 로그인 해 주십시오.');
                // 로그인 페이지로 리다이렉트 또는 다른 로그인 처리를 수행
                window.location.href="/login"
            } else {
                errorStat(error) // 다른 오류는 다시 throw하여 상위 컴포넌트에서 처리
            }

        })
}


export function postApiData (url,utoken,successStat,errorStat,getdata,getparam) {
    var pdata = {}
    var header = {}
    var param = {}

    if(utoken){
        header.authorization = 'bearer ' + $store.state.token
    }
    if(getdata){
        pdata = getdata
    }
    if(getparam){
        param = getparam
    }
    $axios.post( $store.state.basedomain+url
        ,  pdata
        ,{
            headers : header,
            params: param
        }
        )
        .then( function (res) {
            successStat(res);
        })

        .catch( function(error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized 응답 처리
                alert('로그인 정보가 만료되었습니다. 다시 로그인 해 주십시오.');
                // 로그인 페이지로 리다이렉트 또는 다른 로그인 처리를 수행
                window.location.href="/login"
            } else {
                errorStat(error) // 다른 오류는 다시 throw하여 상위 컴포넌트에서 처리
            }

        })
}



export function uploadApiData (url,utoken,successStat,errorStat,inputdata,getparam) {
    var pdata = {}
    var header = {}
    var param = {}

    if(utoken){
        header = { "Content-Type": "multipart/form-data", authorization:'bearer ' + $store.state.token}
    }
    if(inputdata){
        pdata = inputdata
    }
    if(getparam){
        param = getparam
    }
    $axios.post( $store.state.basedomain+url
        ,  pdata
        ,{
            headers : header,
            params: param
        }
    )
        .then( function (res) {
            successStat(res);
        })

        .catch( function(error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized 응답 처리
                alert('로그인 정보가 만료되었습니다. 다시 로그인 해 주십시오.');
                // 로그인 페이지로 리다이렉트 또는 다른 로그인 처리를 수행
                window.location.href="/login"
            } else {
                errorStat(error) // 다른 오류는 다시 throw하여 상위 컴포넌트에서 처리
            }

        })
}

